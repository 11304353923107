// 工具函数，用于组织多个reducer并返回reducer集合
import { combineReducers } from 'redux'
// 默认值
import defaultState from './state.js'

import produce from 'immer'

import { Store } from '@/utils'

// navTaps_redux
const navTaps = (state = defaultState.navTaps, action) => produce(state, draft => {
  Store.setStorageNavTaps(state.slice(0), action);
  switch (action.type) {
    case 'ADD_NAV_TAPS':
      draft.push(action.payload)
      break;

    case 'DELETE_NAV_TAPS':
      draft.splice(action.payload, 1)
      break;

    case 'UPDATE_NAV_TAPS_RELOAD':
      draft[action.payload].reload++
      break;

    case 'UPDATE_NAV_TAPS_SHOW':
      state.forEach((item, i) => {
        draft[i].isShow = action.payload === i
      })
      break;

    case 'CLEAR_NAV_TAPS_SHOW':
      draft.splice(1, state.length - 1)
      break;

    case 'CLOSE_OTHER_NAV_TAPS_SHOW':
      let _cacheNavTap = state.slice(0)[action.payload]
      draft.splice(1, state.length - 1)
      if (action.payload !== 0) draft.push(_cacheNavTap)
      break;

    default:
      return state
  }
})

// layout_redux
const sideBarCollapsed = (state = defaultState.sideBarCollapsed, action) => produce(state, draft => {
  switch (action.type) {
    case 'UPDATE_COLLAPSED':
      return !action.payload

    default:
      break;
  }
})

// 字典类型
const dictionaryType = (state = defaultState.dictionaryType, action) => produce(state, draft => {
  switch (action.type) {
    case 'UPDATE_DICTIONARY_TYPE':
      return action.payload

    default:
      break;
  }
})

// 权限对象
const authority = (state = defaultState.authority, action) => produce(state, draft => {
  switch (action.type) {
    case 'UPDATE_AUTHORITY':
      return action.payload

    default:
      break;
  }
})




export default combineReducers({
  navTaps,
  sideBarCollapsed,
  dictionaryType,
  authority
})
