//    loading弹窗
import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { useSelector} from 'react-redux';
import { Modal, Spin } from 'antd'

import { OPTIONS_MODAL_FUN } from "@/utils"; //引入公共方法

const LoadingAlert = (options, ref) => {
  // 侧边栏开关
  const collapsed = useSelector(state => state.sideBarCollapsed);

  const [visible, setVisible] = useState(false);

  useImperativeHandle(ref, () => ({
    getVisible: () => {
      return visible
    },
    // 打开弹窗
    open: () => {
      setVisible(true)
    },
    close: () => {
      setVisible(false)
    }
  }));

  return (
    <Modal
      {...OPTIONS_MODAL_FUN({
        collapsed: collapsed
      })}
      transitionName=""
      maskTransitionName=""
      width='230px'
      style={{ top: '38.2%' }}
      visible={visible}
      footer={null}
      markClosable={false} // 点击蒙层是否允许关闭
      keyboard={false} // 是否支持键盘 esc 关闭
      closable={false} // 是否显示右上角的关闭按钮
    >
      <div><Spin spinning={visible}></Spin>系统处理中。。。</div>
    </Modal>
  )
}

export default forwardRef(LoadingAlert);
