
import dictionaryModel from "@/model/SystemSetting/dictionaryModel";
import commonModel from "@/model/Global/common";

const LIST = {
  // 账号/工号状态
  STATUS_LIST: [
    { value: 0, title: "正常" },
    { value: 1, title: "禁用" },
    { value: 2, title: "冻结" }
  ],
  // 登录角色
  USER_ROLE_LIST: [
    { value: 0, title: "管理员" },
    { value: 1, title: "后台" },
    { value: 2, title: "保险公司" },
    { value: 3, title: "企业" },
    { value: 4, title: "商户" },
    { value: 5, title: "门店" }
  ],
  // 菜单角色
  MENU_ROLE_LIST: [],
  // 企健付版本号/读卡器类型
  CARDREADER_TYPE_LIST: [
    { value: 1, title: "POS" },
    { value: 2, title: "刷卡" },
    { value: 3, title: "手机" },
    { value: 4, title: "区分操作手册" }
  ],
  // 是否
  WHETHER_LIST: [
    { value: 0, title: "是" },
    { value: 1, title: "否" }
  ],
  WHETHER_LIST_RELEASE: [
    { value: 0, title: "否" },
    { value: 1, title: "是" },
  ],
  // 是否
  WHETHER_LIST_REVERSE: [
    { value: 0, title: "否" },
    { value: 1, title: "是" },
    { value: 2, title: "甘肃模式代收代付" }
  ],
  //是否启用
  ENABLE_LIST: [
    { value: 0, title: "未启用" },
    { value: 1, title: "启用" }
  ],
  //第三方商城标识
  MALLID_LIST: [
    { value: 0, title: "九源洪福堂保外都显示" },
    { value: 1, title: "九源洪福堂保外都不显示" },
    { value: 3, title: "不显示保外，九源洪福堂都显示" },
    { value: 4, title: "只显示保外，九源洪福堂不显示" }
  ],
  //证件类型
  IDType_LIST: [
    { value: 0, title: "身份证" },
    { value: 1, title: "护照" },
    { value: 2, title: "驾照" }
  ],
  // 补卡类型
  CARD_TYPE: [
    { value: 0, title: "企健网发卡" },
    { value: 1, title: "非企健网发卡" }
  ],
  // 登记状态
  CARD_STATUS: [
    { value: 0, title: "未登记" },
    { value: 1, title: "登记成功" },
    { value: 2, title: "登记失败" }
  ],
  // 团单状态  0：正常；1：冻结；2：废除
  GROUP_ORDER_STATUS: [
    { value: 0, title: "正常" },
    { value: 1, title: "冻结" },
    { value: 2, title: "废除" }
  ],
  // 税号状态 0 未填充，1已填充
  TAX_NO_STATUS: [
    { value: 0, title: "未填充" },
    { value: 1, title: "已填充" }
  ],
  // 团单扣款方式 0本地 1泰康端
  ORDER_PAY_TYPE: [
    { value: 0, title: "本地" },
    { value: 1, title: "泰康端" }
  ],
  // 订单状态 0 正常 1 取消 0 退单
  ORDER_STATUS: [
    { value: 0, title: "正常" },
    { value: 1, title: "取消" },
    { value: 2, title: "退单" }
  ],
  // 付款状态 0 已付款 1 未付款
  PAYMENT_STATUS: [
    { value: 0, title: "已付款" },
    { value: 1, title: "未付款" }
  ],
  // 处理结果 0 已处理 1 未处理
  PROCESS_RESULT: [
    { value: 0, title: "已处理" },
    { value: 1, title: "未处理" }
  ],
  // 商品状态
  COMMODITY_STATUS: [
    { value: 0, title: "未分类" },
    { value: 1, title: "分类中" },
    { value: 2, title: "分类完成" },
    { value: 3, title: "审核完成" }
  ],
  // 预约单状态
  APPOINTMENT_STATUS: [
    { value: 0, title: "已预约" },
    { value: 1, title: "已取消" },
    { value: 2, title: "已体检" },
    { value: 3, title: "预约失败" },
    { value: 4, title: "预约中" },
    { value: 5, title: "已出报告" },
    { value: 6, title: "改期中" },
    { value: 7, title: "未到检" }
  ],
  // 理赔日志 操作类型   0新增 2取消
  LOG_CLAIM_TYPE: [
    { value: 0, title: "新增" },
    { value: 2, title: "取消" }
  ],
  BILLING_THEME_STATUS: [
    { value: 0, title: "未开始" },
    { value: 1, title: "修改成功" },
    { value: 2, title: "修改失败" }
  ],
  ONLINE_STATUS: [
    { value: 0, title: "已上线" },
    { value: 1, title: "未上线" },
    { value: 2, title: "暂时闭店" }
  ],
  CARD_G_STATUS_LIST: [
    { value: 0, title: '未制卡' },
    { value: 1, title: '已发放' },
    { value: 3, title: '未激活' },
    { value: 4, title: '备卡' },
    { value: 5, title: '补卡' }
  ],
  AGE_STATUS: [
    { value: 'underThirty', title: '30岁以下' },
    { value: 'thirtyToForty', title: '30-40岁' },
    { value: 'fortyToFifty', title: '40-50岁' },
    { value: 'fiftyToSixty', title: '50-60岁' },
    { value: 'overSixty', title: '60岁以上' },
  ]
};
class ClassGlobal {
  constructor() {
    this.constantKey = {
      accountStatus: "STATUS_LIST",
      userRole: "USER_ROLE_LIST",
      carderaderType: "CARDREADER_TYPE_LIST",
      whether: "WHETHER_LIST",
      enable: "ENABLE_LIST",
      mallId: "MALLID_LIST",
      IDType: "IDType_LIST",
      cardType: "CARD_TYPE",
      cardStatus: "CARD_STATUS",
      groupOrderStatus: "GROUP_ORDER_STATUS",
      taxNoStatus: "TAX_NO_STATUS",
      orderPayType: "ORDER_PAY_TYPE",
      commodityStatus: "COMMODITY_STATUS",
      qjSaleFlag: "WHETHER_LIST_REVERSE",
      isSmartpay: "WHETHER_LIST_RELEASE",
      processResult: "PROCESS_RESULT",
      appointmentStatus: "APPOINTMENT_STATUS",
      logClaimType: "LOG_CLAIM_TYPE",
      billingThemeStatus: "BILLING_THEME_STATUS",
      isMedicareDrugstore: "WHETHER_LIST_REVERSE",
      isEp: "WHETHER_LIST_REVERSE",
      onlineStatus: "ONLINE_STATUS",
      cardGStatusList: "CARD_G_STATUS_LIST",
      ageStatus: "AGE_STATUS"
    };
    this.dictionaryKey = {
      dictHead: "selectDictHeadList", // 所有字典类型
      menuRole: "selectRoleList", // 菜单功能角色
      healthyInfoType: "selectInfoType", // 咨询类型
      provinceAreaList: "selectProvinceAreaList", // 省地区列表
      areaList: "selectAreaList", // 明细地区列表
      operationModule: "selectOperation", //操作模块列表
      advertisement: "selectAdvertisement", //广告渠道
      operationType: "selectOperationType", //操作类型
      cardGStatus: "selectCardStatus", //卡状态
      cardGType: "selectCardType", //卡类型
      announcement: "selectAnnouncement", //公告渠道
      companyName: "selectCompanyName", // 承保单位模糊查询
      storeType: "selectStoreType", //门店类型
      docking: "selecctDocking", //对接方式
      changeType: "selectChangeType", //变动类型
      serviceCharge: "selectServiceCharge", // 服务费模式
      invoiceType: "selectInvoiceType", // 开票类型
      qjCommodityType: "selectQjCommodityType", // 企健网商品分类
      organizationType: "selectOrganizationType", //机构类型
      orderStatus: "selectOrderStatus", // 订单状态
      brandByType: "selectBrandByType", //根据类型获取品牌
      insureCompanyName: "selectInsureCompanyName", // 投保单位模糊查询
      policyType: "selectDictionary", //字典查询下拉
      userRoleDic: "selectUserRoleDic", // 登录角色（字典类型）
      commodityLibrary: "selectCommodityLibrary", //结算主体管理 商品库
      fileGenerationType: "selectFileGenerationType", //对账日志 生成文件类型  商户财务文件日志
      capitalFileGenerationType: "selectCapitalFileGenerationType", //对账日志 生成文件类型  资金方财务文件日志
      strategyType: "getStrategyTypeData", // 策略类型
      strategyPolicyType: "getStrategyPolicyType", // 策略类型
      commodityNameOptions: "getCommodityNameOptions",
      bigBrandOptions: "getBigBrandOptions",
      smallBrandOptions: "getSmallBrandOptions",
      smallBrandOptionsOrder: "getSmallBrandOptionsOrder", //小品牌订单
      sysUserStatus: "getSysUserStatusOptions", // 系统管理 - 状态
      accountCompanyName: "getAccountCompanyName", // 系统管理 - 状态
      interfaceType: "selectInterfaceType",
      companyNameFirst: "selectCompanyNameFirst", // 承保单位模糊查询简称
      medicalStore: "selectMedicalStore", // 根据门店名模糊查询门店信息
      accountCompany: "selectAccountCompanyByCompanyName", // 商户结算主体模糊
    };

    this.dictionary = {};
    this.dictHeadList = []; // 所有字典类型
    this.menuRoleList = []; // 菜单功能角色
    this.infoTypeList = []; //健康资讯类型
    this.provinceAreaList = []; // 省地区列表
    this.areaList = []; // 明细地区列表
    this.operationList = []; //操作模块
    this.advertisementList = []; //广告渠道
    this.operationTypeList = []; //操作类型
    this.cardStatusList = []; //卡状态
    this.cardTypeList = []; //卡类型
    this.announcementList = []; //公告渠道
    this.companyNameList = []; // 承保单位模糊查询
    this.storeTypeList = []; //门店类型
    this.dockingList = []; //对接方式
    this.serviceChargeList = []; //对接方式
    this.invoiceTypeList = []; // 开票类型
    this.changeTypeList = []; //对接方式
    this.qjCommodityTypeList = []; //企健网商品分类-药品白名单列表
    this.dictionaryList = []; //字典查询下拉
    this.userRoleDic = []; // 登录角色-字典方式
    this.commodityLibrary = []; // 结算主体管理 - 商品库
    this.fileGenerationType = []; //对账日志 生成文件类型  商户财务文件生成文件日志
    this.capitalFileGenerationType = []; //对账日志 生成文件类型 资金方财务文件日志
    this.commodityNameOptions = [];
    this.bigBrandOptions = [];
    this.smallBrandOptions = [];
    this.accountCompanyNameOptions = []; //商户名称
    this.interfaceTypeList = []; //接口类型
    /**
     * 如果defaultVal为空是，过滤默认数据
     * @param {string, null} defaultVal 默认类型
     * @param {array} list 列表，被过滤对象
     * @returns array
     */
    this.filterDefaultValList = (type, defaultVal, list) => {
      switch (type) {
        case "CONSTANT":
          if (!(list && list.length)) {
            return []
          }
          if (!defaultVal) {
            return list.filter(item => item.title)
          } else {
            return list
          }

        case "FUNCTION":
          return new Promise((resolve, reject) => {
            list.then(res => {
              if (!(res && res.length)) {
                resolve([])
              }
              if (!defaultVal) {
                resolve(res.filter(item => item.title))
              } else {
                resolve(res)
              }
            }).catch(err => {
              let _list = []
              if (err && err.list) {
                if (!(err.list && err.list.length)) {
                  _list = []
                }
                if (!defaultVal) {
                  _list = err.list.filter(item => item.title)
                } else {
                  _list = err.list
                }
              }
              reject({
                ...err,
                list: _list
              })
            })
          })

        default:
          return [];
      }

    }
  }

  /**
   *
   * @param {string} dictionaryKey 目录名称
   * @param {string} defaultVal 默认字段
   * @param {object} options 参数
   */
  getOptionsList(dictionaryKey, defaultVal = "请选择", options = null) {
    let _constantKey = this.constantKey[dictionaryKey];
    let _dictionaryKey = this.dictionaryKey[dictionaryKey];
    if (!_constantKey && !_dictionaryKey) return [];
    let type = _constantKey ? "CONSTANT" : "FUNCTION";
    switch (type) {
      case "CONSTANT":
        if (!_constantKey) return [];
        let constantList = this.setSelectDefaultVal(LIST[_constantKey], defaultVal);
        return this.filterDefaultValList(type, defaultVal, constantList)

      case "FUNCTION":
        if (!_dictionaryKey) return [];
        let functionList = this[_dictionaryKey](defaultVal, options);
        return this.filterDefaultValList(type, defaultVal, functionList)

      default:
        return [];
    }
  }

  // 添加默认option下拉选项
  setSelectDefaultVal(list, defaultVal = "请选择") {
    if (!list) return [{ value: "", title: defaultVal }];
    let _list = list.slice(0);
    _list.unshift({ value: "", title: defaultVal });
    return _list;
  }

  // 获取所有字典类型
  selectDictHeadList(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      dictionaryModel
        .selectDictHeadList({
          params: {
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.headCode,
                title: res.headName
              };
            });
            this.dictHeadList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.dictHeadList);
          } else {
            reject({ message: "获取所有字典类型失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取所有字典类型失败", err: err });
        });
    });
  }

  // 获取菜单功能角色
  selectRoleList(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getListRole({
          data: {
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.id,
                title: res.roleName
              };
            });
            this.menuRoleList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.menuRoleList);
          } else {
            reject({ message: "获取菜单功能角色失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取菜单功能角色失败", err: err });
        });
    });
  }
  //获取资讯类型
  selectInfoType(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getHealthyInfoType({
          data: {
            pageNum: 1,
            pageSize: 100,
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.list.length) {
            let datas = res.datas.list.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.id,
                title: res.title
              };
            });
            this.infoTypeList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.infoTypeList);
          } else {
            reject({ message: "获取资讯类型失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取资讯类型失败", err: err });
        });
    });
  }
  // 省地区列表
  selectProvinceAreaList(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getProvinceList({
          data: {
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.id,
                title: res.areaName
              };
            });
            this.provinceAreaList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.provinceAreaList);
          } else {
            reject({ message: "获取地区列表失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取地区列表失败", err: err });
        });
    });
  }
  // 地区列表
  selectAreaList(defaultVal = "请选择", options) {
    return new Promise((resolve, reject) => {
      commonModel
        .getAreaList({
          data: {
            ...(options.data ? options.data : {}),
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.id,
                title: res.areaName
              };
            });
            this.areaList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.areaList);
          } else {
            reject({ message: "获取地区列表失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取地区列表失败", err: err });
        });
    });
  }
  // 品牌类型
  selectBrandByType(defaultVal = "请选择", options) {
    console.log(defaultVal, options);
    return new Promise((resolve, reject) => {
      commonModel
        .getBrandByType({
          data: {
            ...(options.data ? options.data : {}),
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.success === true) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                title: res
              };
            });
            this.brandByType = this.setSelectDefaultVal(list);
            function unique(arr) {
              var newArr = [];
              for (var i = 0; i < arr.length; i++) {
                if (newArr.indexOf(arr[i]) === -1) {
                  newArr.push(arr[i]);
                }
              }
              return newArr;
            }
            let arr = unique(this.brandByType);
            arr.forEach(item => {
              item = { title: item };
            });
            resolve(arr);
          } else {
            reject({ message: "获取品牌失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取品牌失败", err: err });
        });
    });
  }
  //操作模块
  selectOperation(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "sys_optlog_module",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName
              };
            });
            this.operationList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.operationList);
          } else {
            reject({ message: "获取操作模板失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取操作模板失败", err: err });
        });
    });
  }
  //发布渠道
  selectAdvertisement(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "d_adv_type",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName,
                disabled: res.status === 1 ? true : false
              };
            });
            this.advertisementList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.advertisementList);
          } else {
            reject({ message: "获取发布渠道失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取发布渠道失败", err: err });
        });
    });
  }
  //操作类型
  selectOperationType(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "card_opt_flag",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName
              };
            });
            this.operationList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.operationList);
          } else {
            reject({ message: "获取操作类型失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取操作类型失败", err: err });
        });
    });
  }
  //卡号生成 -- 卡状态
  selectCardStatus(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "c_card_status",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          console.log(res);
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName
              };
            });
            this.operationList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.operationList);
          } else {
            reject({ message: "获取卡状态失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取卡状态失败", err: err });
        });
    });
  }

  //卡号生成 -- 卡类型
  selectCardType(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "c_card_type",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName
              };
            });
            this.cardTypeList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.cardTypeList);
          } else {
            reject({ message: "获取卡类型失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取卡类型失败", err: err });
        });
    });
  }
  //公告发布渠道
  selectAnnouncement(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "sys_notice_flag",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          console.log("resssss", res);
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName,
                status: res.status
              };
            });
            this.announcementList = list;
            resolve(this.announcementList);
          } else {
            reject({ message: "获取发布渠道失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取发布渠道失败", err: err });
        });
    });
  }
  //门店管理-门店类型
  selectStoreType(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "d_company_type",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName
              };
            });
            this.storeTypeList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.storeTypeList);
          } else {
            reject({ message: "获取门店类型失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取门店类型失败", err: err });
        });
    });
  }
  // 团单管理 - 承保单位模糊查询
  selectCompanyName(defaultVal = "请选择", options) {
    return new Promise((resolve, reject) => {
      commonModel
        .listInsuranceCompanyInfo({
          data: {
            ...(options.data ? options.data : {}),
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            console.log(datas, "selectCompanyName");
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.companyName,
                title: res.companyName,
                firstShortName: res.firstShortName,
                secondShortName: res.secondShortName,
                areaId: res.areaId
              };
            });
            this.cardTypeList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.cardTypeList);
          } else {
            reject({
              message: "获取承保单位模糊查询失败",
              err: res,
              list: this.setSelectDefaultVal([], defaultVal)
            });
          }
        })
        .catch(err => {
          reject({
            message: "获取承保单位模糊查询失败",
            err: err,
            list: this.setSelectDefaultVal([], defaultVal)
          });
        });
    });
  }
  //门店管理 - 对接方式
  selecctDocking(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "d_company_connect_type",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          console.log("resssssss", res);
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = [];
            datas.forEach(res => {
              // console.log(res);
              if (res.status === 0) {
                list.push({
                  id: res.id,
                  value: res.dictValue,
                  title: res.dictName
                });
              }
            });
            this.dockingList = this.setSelectDefaultVal(list, defaultVal);
            console.log(this.dockingList);

            resolve(this.dockingList);
          } else {
            reject({ message: "获取对接方式失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取对接方式失败", err: err });
        });
    });
  }

  selectChangeType(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "order_amount_change_log",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          console.log("resssssss", res);
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = [];
            datas.forEach(res => {
              // console.log(res);
              if (res.status === 0) {
                list.push({
                  id: res.id,
                  value: res.dictValue,
                  title: res.dictName
                });
              }
            });
            this.changeTypeList = this.setSelectDefaultVal(list, defaultVal);
            console.log(this.changeTypeList);

            resolve(this.changeTypeList);
          } else {
            reject({ message: "获取变动类型失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取变动类型失败", err: err });
        });
    });
  }

  //结算基础 - 服务费模式
  selectServiceCharge(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "a_account_service_type",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          console.log("resssssss", res);
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = [];
            datas.forEach(res => {
              // console.log(res);
              if (res.status === 0) {
                list.push({
                  id: res.id,
                  value: res.dictValue,
                  title: res.dictName
                });
              }
            });
            this.serviceChargeList = this.setSelectDefaultVal(list, defaultVal);
            console.log(this.serviceChargeList);

            resolve(this.serviceChargeList);
          } else {
            reject({ message: "获取服务费模式失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取服务费模式失败", err: err });
        });
    });
  }
  // 开票类型
  selectInvoiceType(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "a_account_pay_invoice_type"
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = [];
            datas.forEach(res => {
              // console.log(res);
              if (res.status === 0) {
                list.push({
                  id: res.id,
                  value: res.dictValue,
                  title: res.dictName
                });
              }
            });
            this.invoiceTypeList = this.setSelectDefaultVal(list, defaultVal);
            console.log(this.invoiceTypeList)
            resolve(this.invoiceTypeList);
          } else {
            reject({ message: "获取开票类型失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取开票类型失败", err: err });
        });
    });
  }
  //企健网商品分类-药品白名单列表
  selectQjCommodityType(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "store_qj_commodity_type",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName
              };
            });
            this.qjCommodityTypeList = list;
            resolve(this.qjCommodityTypeList);
          } else {
            reject({ message: "获取企健网商品分类失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取企健网商品分类失败", err: err });
        });
    });
  }

  // 订单管理 - 机构类型选择
  selectOrganizationType(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "d_company_type",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName
              };
            });
            this.storeTypeList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.storeTypeList);
          } else {
            reject({ message: "获取门店类型失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取门店类型失败", err: err });
        });
    });
  }

  // 团单管理 - 投保单位模糊搜索
  selectInsureCompanyName(defaultVal = "请选择", options) {
    return new Promise((resolve, reject) => {
      commonModel
        .listInsureCompanyInfo({
          data: {
            ...(options.data ? options.data : {}),
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.companyName,
                title: res.companyName
              };
            });
            this.cardTypeList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.cardTypeList);
          } else {
            reject({
              message: "获取投保单位模糊搜索失败",
              err: res,
              list: this.setSelectDefaultVal([], defaultVal)
            });
          }
        })
        .catch(err => {
          reject({
            message: "获取投保单位模糊搜索失败",
            err: err,
            list: this.setSelectDefaultVal([], defaultVal)
          });
        });
    });
  }
  // 登录角色 - 字典类型
  selectUserRoleDic(defaultVal = "请选择", options) {
    return new Promise((resolve, reject) => {
      dictionaryModel
        .selectDictDetail({
          data: {
            ...(options.data ? options.data : {}),
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName
              };
            });
            this.userRoleDic = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.userRoleDic);
          } else {
            reject({
              message: "获取登录角色 - 字典类型失败",
              err: res,
              list: this.setSelectDefaultVal([], defaultVal)
            });
          }
        })
        .catch(err => {
          reject({
            message: "获取登录角色 - 字典类型失败",
            err: err,
            list: this.setSelectDefaultVal([], defaultVal)
          });
        });
    });
  }
  //结算主体管理 - 商品库
  selectCommodityLibrary(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "s_account_company_goods_flag",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName
              };
            });
            this.commodityLibrary = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.commodityLibrary);
          } else {
            reject({ message: "获取商品库失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取商品库失败", err: err });
        });
    });
  }

  //字典获取下拉
  selectDictionary(defaultVal = "请选择", code) {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: code,
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName
              };
            });
            this.dictionaryList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.dictionaryList);
          } else {
            reject({ message: "获取下拉菜单失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取下拉菜单失败", err: err });
        });
    });
  }
  // 订单关系 - 订单状态
  selectOrderStatus(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "o_order_status",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName
              };
            });
            this.storeTypeList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.storeTypeList);
          } else {
            reject({ message: "获取订单状态失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取订单状态失败", err: err });
        });
    });
  }

  //对账日志 - 文件生成状态
  selectFileGenerationType(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "account_generate_file_type",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName
              };
            });
            this.fileGenerationType = this.setSelectDefaultVal(
              list,
              defaultVal
            );
            resolve(this.fileGenerationType);
          } else {
            reject({ message: "获取文件生成状态失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取文件生成状态失败", err: err });
        });
    });
  }
  //对账日志 - 文件生成状态 资金方
  selectCapitalFileGenerationType(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "capital_file_type",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName
              };
            });
            this.capitalFileGenerationType = this.setSelectDefaultVal(
              list,
              defaultVal
            );
            resolve(this.capitalFileGenerationType);
          } else {
            reject({ message: "获取文件生成状态失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取文件生成状态失败", err: err });
        });
    });
  }

  // 日志管理 - 个单策略日志
  getStrategyTypeData(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "strategy_type",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName
              };
            });
            this.storeTypeList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.storeTypeList);
          } else {
            reject({ message: "获取策略类型失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取策略类型失败", err: err });
        });
    });
  }
  // 日志管理 - 团单策略日志
  getStrategyPolicyType(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "strategy_policy_type",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName
              };
            });
            this.storeTypeList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.storeTypeList);
          } else {
            reject({ message: "获取策略类型失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取策略类型失败", err: err });
        });
    });
  }
  // 订单管理 - 商品名称模糊查询
  getCommodityNameOptions(defaultVal = "请选择", options) {
    return new Promise((resolve, reject) => {
      commonModel
        .operateCommodityByName({
          data: {
            ...(options.data ? options.data : {}),
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(item => {
              return {
                value: item.id,
                title: item.commodityName
              };
            });
            this.commodityNameOptions = this.setSelectDefaultVal(
              list,
              defaultVal
            );
            resolve(this.commodityNameOptions);
          } else {
            reject({
              message: "获取商品名称模糊查询失败",
              err: res,
              list: this.setSelectDefaultVal([], defaultVal)
            });
          }
        })
        .catch(err => {
          reject({
            message: "获取商品名称模糊查询失败",
            err: err,
            list: this.setSelectDefaultVal([], defaultVal)
          });
        });
    });
  }

  // 订单管理 - 大品牌模糊查询
  getBigBrandOptions(defaultVal = "请选择", options) {
    return new Promise((resolve, reject) => {
      commonModel
        .operateBigBrand({
          data: {
            ...(options.data ? options.data : {}),
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map((item, index) => {
              return {
                value: index,
                title: item.bigBrand
              };
            });
            this.bigBrandOptions = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.bigBrandOptions);
          } else {
            reject({
              message: "获取大品牌模糊查询失败",
              err: res,
              list: this.setSelectDefaultVal([], defaultVal)
            });
          }
        })
        .catch(err => {
          reject({
            message: "获取大品牌模糊查询失败",
            err: err,
            list: this.setSelectDefaultVal([], defaultVal)
          });
        });
    });
  }
  // 订单管理 - 小品牌模糊查询
  getSmallBrandOptions(defaultVal = "请选择", options) {
    return new Promise((resolve, reject) => {
      commonModel
        .operateSmallBrand({
          data: {
            ...(options.data ? options.data : {}),
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map((item, index) => {
              return {
                value: index,
                title: item.smallBrand
              };
            });
            this.smallBrandOptions = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.smallBrandOptions);
          } else {
            reject({
              message: "获取小品牌模糊查询失败",
              err: res,
              list: this.setSelectDefaultVal([], defaultVal)
            });
          }
        })
        .catch(err => {
          reject({
            message: "获取小品牌模糊查询失败",
            err: err,
            list: this.setSelectDefaultVal([], defaultVal)
          });
        });
    });
  }
  // 订单管理 - 小品牌模糊查询订单用
  getSmallBrandOptionsOrder(defaultVal = "请选择", options) {
    return new Promise((resolve, reject) => {
      commonModel
        .operateSmallBrandOrder({
          data: {
            ...(options.data ? options.data : {}),
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map((item, index) => {
              return {
                value: index,
                title: item.smallBrand
              };
            });
            this.operateSmallBrandOrder = this.setSelectDefaultVal(
              list,
              defaultVal
            );
            resolve(this.operateSmallBrandOrder);
          } else {
            reject({
              message: "获取小品牌模糊查询失败",
              err: res,
              list: this.setSelectDefaultVal([], defaultVal)
            });
          }
        })
        .catch(err => {
          reject({
            message: "获取小品牌模糊查询失败",
            err: err,
            list: this.setSelectDefaultVal([], defaultVal)
          });
        });
    });
  }

  // 账号管理 - 状态
  getSysUserStatusOptions(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "sys_user_status",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName
              };
            });
            list = this.setSelectDefaultVal(list, defaultVal);
            resolve(list);
          } else {
            reject({ message: "获取账号管理 - 状态失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取账号管理 - 状态失败", err: err });
        });
    });
  }

  // 商户名称模糊查询
  getAccountCompanyName(defaultVal = "请选择", options) {
    return new Promise((resolve, reject) => {
      commonModel
        .accountCompanyName({
          data: {
            ...(options.data ? options.data : {}),
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map((item, index) => {
              return {
                value: item.companyId,
                title: item.companyName
              };
            });
            this.accountCompanyNameOptions = this.setSelectDefaultVal(
              list,
              defaultVal
            );
            resolve(this.accountCompanyNameOptions);
          } else {
            reject({
              message: "获取商户名称模糊查询失败",
              err: res,
              list: this.setSelectDefaultVal([], defaultVal)
            });
          }
        })
        .catch(err => {
          reject({
            message: "获取商户名称模糊查询失败",
            err: err,
            list: this.setSelectDefaultVal([], defaultVal)
          });
        });
    });
  }

  //接口类型
  selectInterfaceType(defaultVal = "请选择") {
    return new Promise((resolve, reject) => {
      commonModel
        .getoperationModule({
          data: {
            headCode: "d_openapi_interface_type",
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.dictValue,
                title: res.dictName,
                disabled: res.status === 1 ? true : false
              };
            });
            this.interfaceTypeList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.interfaceTypeList);
          } else {
            reject({ message: "获取接口类型失败", err: res });
          }
        })
        .catch(err => {
          reject({ message: "获取接口类型失败", err: err });
        });
    });
  }

  // 承保单位一级简称模糊查询
  selectCompanyNameFirst(defaultVal = "请选择", options) {
    return new Promise((resolve, reject) => {
      commonModel
        .listInsuranceCompanyFirstInfo({
          data: {
            ...(options.data ? options.data : {}),
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.id,
                value: res.firstShortName,
                title: res.firstShortName,
                companyName: res.companyName,
                secondShortName: res.secondShortName,
                areaId: res.areaId
              };
            });
            this.cardTypeList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.cardTypeList);
          } else {
            reject({
              message: "获取承保单位一级简称模糊查询失败",
              err: res,
              list: this.setSelectDefaultVal([], defaultVal)
            });
          }
        })
        .catch(err => {
          reject({
            message: "获取承保单位一级简称模糊查询失败",
            err: err,
            list: this.setSelectDefaultVal([], defaultVal)
          });
        });
    });
  }

  // 根据门店名模糊查询门店信息
  selectMedicalStore(defaultVal = "请选择", options) {
    return new Promise((resolve, reject) => {
      commonModel
        .getMedicalStoreByName({
          data: {
            ...(options.data ? options.data : {}),
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          console.log('res', res)
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.companyId,
                value: res.companyName,
                title: res.companyName
              };
            });
            this.cardTypeList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.cardTypeList);
          } else {
            reject({
              message: "获取门店信息模糊查询失败",
              err: res,
              list: this.setSelectDefaultVal([], defaultVal)
            });
          }
        })
        .catch(err => {
          console.log('error',err)
          reject({
            message: "获取门店信息模糊查询失败",
            err: err,
            list: this.setSelectDefaultVal([], defaultVal)
          });
        });
    });
  }

  // 商户结算主体模糊
  selectAccountCompanyByCompanyName(defaultVal = "请选择", options) {
    return new Promise((resolve, reject) => {
      commonModel
        .getAccountCompanyByCompanyName({
          params: {
            ...(options.params ? options.params : {}),
            noLoadingRef: 'yes'
          }
        })
        .then(res => {
          if (res.datas && res.datas.length) {
            let datas = res.datas.slice(0);
            let list = datas.map(res => {
              return {
                id: res.companyId,
                value: res.companyName,
                title: res.companyName
              };
            });
            this.cardTypeList = this.setSelectDefaultVal(list, defaultVal);
            resolve(this.cardTypeList);
          } else {
            reject({
              message: "获取商户结算主体模糊查询失败",
              err: res,
              list: this.setSelectDefaultVal([], defaultVal)
            });
          }
        })
        .catch(err => {
          reject({
            message: "获取商户结算主体模糊查询失败",
            err: err,
            list: this.setSelectDefaultVal([], defaultVal)
          });
        });
    });
  }

  getDictionary(key) {
    //   return new Promise((resolve, reject) => {
    //     if (this.dictionary[key].length) resolve(this.dictionary[key])
    //     else {
    //       commonModel.getDictionaryValueList({
    //         data: {
    //           dictionaryType: this.dictionaryKey[key]
    //         }
    //       }).then(({ data }) => {
    //         if (data.datas) {
    //           this.dictionary[key] = data.datas
    //           resolve(data.datas)
    //         } else {
    //           errorMsg(`获取数据字典 ${key} 错误`)
    //         }
    //       })
    //     }
    //   })
  }
}

const globalObj = new ClassGlobal();
export default globalObj;
