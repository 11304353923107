import React, { memo, useState } from 'react'
import { dropByCacheKey, getCachingKeys } from 'react-router-cache-route'

import { Tabs, Menu, Dropdown, Button } from 'antd'
import Icon, { DownOutlined, FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons'

import { withRouter, useHistory } from "react-router-dom"
// redux
import { useSelector, useDispatch } from 'react-redux'
import { deleteNavTaps, updateNavTapsReload, clearNavTaps, closeOtherNavTaps } from '@/store/actions'

import './TabBar.less'

const { TabPane } = Tabs

const menuList = [
  { key: 'reload', txt: '刷新当前' },
  { key: 'close', txt: '关闭当前' },
  { key: 'closeAll', txt: '全部关闭' },
  { key: 'closeOther', txt: '除此之外全部关闭' },
]

const TabBar = ({ location }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const navTapsState = useSelector(state => state.navTaps);
  const [isFullScreen, setIsFullScreen] = useState(false);

  // 标签点击
  const handleClick = (key, event) => {
    history.push(key)
  }

  const dropCache = (id) => {
    if (getCachingKeys().includes(id)) {
      dropByCacheKey(id)
    }
  }

  // 标签删除
  const handleClose = (targetKey, action) => {
    switch (action) {
      case 'remove':
        let deleteIndex = navTapsState.findIndex(item => item.key === targetKey)
        toNearTaps(navTapsState, deleteIndex)
        dispatch(deleteNavTaps(deleteIndex))
        dropCache(navTapsState[deleteIndex].key)
        break;

      default:
        break;
    }
  }

  // 删除后跳转相邻标签页
  function toNearTaps (navTaps, deleteIndex) {
    // 当deleteIndex为0时，表示跳转到首页
    if (deleteIndex === 0) {
      let linkTo = navTaps[0].key
      history.push(linkTo)
    } else if (navTaps.length - 1 === deleteIndex) {
      // 删除的是最后一个tab
      let linkTo = navTaps[deleteIndex - 1].key
      history.push(linkTo)
    } else {
      let linkTo = navTaps[deleteIndex + 1].key
      history.push(linkTo)
    }
  }

  const handleMenuClick = (e) => {
    const targetKey = location.pathname
    let selectedIndex = navTapsState.findIndex(item => item.key === targetKey)

    switch (e.key) {
      // 刷新
      case 'reload':
        if (typeof navTapsState[selectedIndex] === 'undefined') return false
        dropCache(navTapsState[selectedIndex].key)
        dispatch(updateNavTapsReload(selectedIndex))
        break;
      // 关闭当前
      case 'close':
        if (typeof navTapsState[selectedIndex] === 'undefined') return false
        if (selectedIndex === 0) return false
        toNearTaps(navTapsState, selectedIndex)
        dropCache(navTapsState[selectedIndex].key)
        dispatch(deleteNavTaps(selectedIndex))
        break;
      case 'closeAll':
        toNearTaps(navTapsState, 0)
        navTapsState.forEach(item => {
          dropCache(item.key)
        })
        dispatch(clearNavTaps())

        break;
      // // 关闭其他
      case 'closeOther':
         navTapsState.forEach((item, index) => {
          if (selectedIndex !== index) {
            dropCache(item.key)
          }
        })
        dispatch(closeOtherNavTaps(selectedIndex))
        break;

      default:
        break;
    }
  }

  //进入全屏
  const requestFullScreen = () => {
    let de = document.documentElement
    if (de.requestFullscreen) {
      de.requestFullscreen()
    } else if (de.mozRequestFullScreen) {
      de.mozRequestFullScreen()
    } else if (de.webkitRequestFullScreen) {
      de.webkitRequestFullScreen()
    }
  };

  //退出全屏
  const exitFullscreen = () => {
    let de = document
    if (de.exitFullscreen) {
      de.exitFullscreen()
    } else if (de.mozCancelFullScreen) {
    de.mozCancelFullScreen()
    } else if (de.webkitCancelFullScreen) {
      de.webkitCancelFullScreen()
    }
  };

  // 页面全屏
  const fullScreen = () => {
    if (!isFullScreen) {
      setIsFullScreen(true)
      requestFullScreen()
    } else {
      setIsFullScreen(false)
      exitFullscreen()
    }
  };


  return (
    <div className="tab-bar__container flex">
      <Tabs
        className="tab-bar__tab"
        hideAdd
        type="editable-card"
        activeKey={location.pathname}
        onTabClick={handleClick.bind(this)}
        onEdit={handleClose.bind(this)}
        >
        {navTapsState.map((pane) => (
          <TabPane key={pane.key} tab={pane.title} closable={pane.key !== '/home'}></TabPane>
          ))}
      </Tabs>

      <div className="tab-bar__action flex">
        {/* 菜单操作按钮集 */}
        <Dropdown className="tab-bar__dropdown" trigger="click" overlay={ (
          <Menu onClick={handleMenuClick.bind(this)}>
            { menuList.map(item => (
              <Menu.Item key={item.key}>
                {item.txt}
              </Menu.Item>
            )) }
          </Menu>
        ) }>
        <Button type="text" size="small"> 页面操作<DownOutlined /> </Button>

        </Dropdown>
        {/* 全屏按钮 */}
        <Button type="text" size="small" className="tab-bar__full-btn flex flex-align-items-center">
          <Icon component={isFullScreen ? FullscreenExitOutlined : FullscreenOutlined} className="tab-bar__full-screen" onClick={fullScreen.bind(this)} />
        </Button>
      </div>
    </div>
  )
}

export default withRouter(memo(TabBar))
