import React, { useEffect }  from 'react'
import { Form, Input } from 'antd'
import { withRouter } from "react-router-dom"
import { KeepAlive } from 'react-keep-alive'
// utils
import { OPTIONS_INPUT_LENGTH, Utils } from '@/utils'
// redux
import { useSelector } from 'react-redux'

const Sandwiches = () => {
  const [form] = Form.useForm()
  const navTapsState = useSelector(state => state.navTaps)

  useEffect(() => {
    Utils.effectEvent('/sandwiches', function (num) {
      console.log('reload')
      form.resetFields()
    })
  }, [navTapsState, form])

  return (
    <KeepAlive id="Sandwiches" name="Sandwiches">
      <h2>Sandwiches</h2>
      <Form form={form}>
        <Form.Item label="名称" name="name">
          <Input {...OPTIONS_INPUT_LENGTH} />
        </Form.Item>
      </Form>
    </KeepAlive>
  )
}

export default withRouter(Sandwiches)
