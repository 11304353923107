import React from 'react'
import {
  BrowserRouter as Router,
} from "react-router-dom"
import renderRoutes from "@/routes/renderRoutes"

// data
import routes from '@/routes/router'

import './App.less';

function App() {
  return (
    <Router>
      {renderRoutes(routes)}
    </Router>
  );
}

export default App;
