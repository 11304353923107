// utils
import { NAV_TAPS_INITIAL, Storage } from '@/utils'



export default {
  // layout
  // 顶部导航
  navTaps: Storage.getStorage('NAV_TAPS') || NAV_TAPS_INITIAL,
  // 导航页面缓存
  // navCache: Storage.getStorage('NAV_CACHE') || NAV_CACHE_INITIAL,

  sideBarCollapsed: false, // 打开侧边栏

  dictionaryType: '', // 字典类型

  authority: null // 权限对象
}
