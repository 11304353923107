import { Request } from '@/utils'

const fetch = Request.httpAxios
const TIME_OUT = Request.TIME_OUT

class Model {
  constructor() {
    this.requestServerPrefix = ''
  }

  api(options = {}) {
    if (!options.method) options.method = 'get'

    return new Promise((resolve, reject) => {
      let request
      let method = options.method.toLowerCase()
      let config = {
        method: method,
        url: this.requestServerPrefix + options.url,
        headers: options.headers || undefined,
        responseType: options.responseType || undefined, // 请求类型
        timeout: options.timeout || TIME_OUT, // 请求超时时间
        transferError: options.transferError || undefined // 转接报错信息
      }
      // console.log(config, 'config');
      switch (method) {
        case 'get':
        case 'delete':
          request = fetch({
            ...config,
            params: options.params
          })
          break
        case 'post':
        case 'put':
          request = fetch({
            ...config,
            data: options.data ? options.data : {data: {}}
          })
          break
        default:
      }
      request
        .then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
    })
  }

  get (options = {}) {
    options.method = 'get'
    return this.api(options)
  }

  post (options = {}) {
    options.method = 'post'
    return this.api(options)
  }

  put (options = {}) {
    options.method = 'put'
    return this.api(options)
  }

  delete (options = {}) {
    options.method = 'delete'
    return this.api(options)
  }
}

export default Model
