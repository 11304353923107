import React, { memo } from 'react'

import { Menu, Logo } from '@/app/Layout'

const SideBar = () => {

  return (
    <div>
      <Logo />
      <Menu />
    </div>
  )

}

export default memo(SideBar)
