/**
 * navTaps导航相关
 * @param {*} data
 */
// 添加导航项
export function addNavTaps (data) {
  return (dispatch, getState) => {
    dispatch({ type: 'ADD_NAV_TAPS', payload: data })
  }
}

// 删除导航项
export function deleteNavTaps (data) {
  return (dispatch, getState) => {
    dispatch({ type: 'DELETE_NAV_TAPS', payload: data })
  }
}

// 更新导航项的reload数值
export function updateNavTapsReload (data) {
  return (dispatch, getState) => {
    dispatch({ type: 'UPDATE_NAV_TAPS_RELOAD', payload: data })
  }
}

// 更新导航项的show数值
export function updateNavTapsShow (data) {
  return (dispatch, getState) => {
    dispatch({ type: 'UPDATE_NAV_TAPS_SHOW', payload: data })
  }
}

// 删除全部导航项
export function clearNavTaps () {
  return (dispatch, getState) => {
    dispatch({ type: 'CLEAR_NAV_TAPS_SHOW' })
  }
}

// 删除其他导航项
export function closeOtherNavTaps (data) {
  return (dispatch, getState) => {
    dispatch({ type: 'CLOSE_OTHER_NAV_TAPS_SHOW', payload: data })
  }
}

// 更新侧边栏展开状态
export function updateCollapsed (data) {
  return (dispatch, getState) => {
    dispatch({ type: 'UPDATE_COLLAPSED', payload: data })
  }
}

export function updateDictionaryType (data) {
  return (dispatch, getState) => {
    dispatch({ type: 'UPDATE_DICTIONARY_TYPE', payload: data })
  }
}

// 更新权限对象
export function updateAuthority (data) {
  return (dispatch, getState) => {
    dispatch({ type: 'UPDATE_AUTHORITY', payload: data })
  }
}
