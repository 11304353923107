import loadingRequests from './loadingRequests';

class LoadingRef {
  constructor() {
    if(!LoadingRef.instance) {
      // 将 this 挂载到单例上
      LoadingRef.instance = this

      // ref相关
      this.ref = null;
    }
    return LoadingRef.instance
  }

  setRef (ref) {
    if (this.ref && this.ref.current) return false
    this.ref = ref
  }
}

class RequestLoading extends LoadingRef {
  constructor(props) {
    super(props);
    this.requestCount = 0; // 请求次数
    this.requestTimeIdList = []; // 请求列表
  }

  validateRequest = (url) => {
    if (!loadingRequests || loadingRequests.length === 0) return true
    return loadingRequests.includes(url)
  }

  handleOpen = () => {
    if (!this.ref) return false
    this.ref.current.open()
  }

  handleClose = () => {
    if (!this.ref) return false
    this.ref.current.close()
  }

  handleOpenByUrl = (url) => {
    if (!this.validateRequest(url)) return false
    if (!this.ref) return false
    let timer = setTimeout(() => {
      this.ref.current.open()
    }, 0)
    this.requestTimeIdList.push({ url, timer })
  }

  handleCloseByUrl = (url) => {
    if (!this.validateRequest(url)) return false
    if (!this.ref) return false
    let findedIndex = undefined
    let findedTimeId = this.requestTimeIdList.find((item, index) => {
      findedIndex = index;
      return item.url === url;
    })
    if (findedTimeId && findedTimeId.timer) {
      this.requestTimeIdList.splice(findedIndex, 1);
      clearTimeout(findedTimeId.timer)
    }
    this.ref.current.close()
  }
}

export default (new RequestLoading())
