import _ from 'lodash'

const returnStorage = () => {
  if (!window.localStorage) {
    alert("浏览器不支持localstorage")
    return false
  }
  return window.localStorage
}

/**
 * 存储localStorage键值对
 * @param {String} name
 * @param {*} value
 */
export const setStorage = (name, value) => {
  let strVal = ''
  let storage = returnStorage()
  // 如果是对象或者数组，使用stringify转换为字符串
  if (_.isObject(value)) {
    strVal = JSON.stringify(value)
  } else {
    strVal = _.isString(value) ? value : ''
  }
  storage.setItem(name, strVal);
}

/**
 * 获取localStorage对应的值
 * @param {String} name
 */
export const getStorage = (name) => {
  let storage = returnStorage()
  let value = storage.getItem(name)
  if (!value) {
    return false
  }
  // 如果数据是对象或数组，使用parse解析数据
  if (_.startsWith(value, '{') || _.startsWith(value, '[')) {
    return JSON.parse(value)
  }
  return value
}

export default {
  getStorage, // 获取本地缓存 （localStorage）
  setStorage, // 设置本地缓存操作 （localStorage）
}
