import {
  numberInteger,
  numberDecimals,
  numberTwoDecimals,
  numberFourDecimals,
  positiveInteger,
  userRegular,
  userNameRegular,
  emailRegular,
  telephone,
  certificateNo,
  userPasswordRegular,
  userSpecific,
  numberThousandthSeparator,
  floatInteger,
  stringIp,
  numberSwitch
} from "./regular-expression";
import moment from "moment";

const ruleCommon = (text, mode = "输入", whitespace = true) => {
  if (whitespace)
    return [{ required: true, whitespace: true, message: `请${mode}${text}` }];
  else return [{ required: true, message: `请${mode}${text}` }];
};
const ruleSelect = (text, mode = "选择", whitespace = true) => {
  if (whitespace)
    return [{ required: true, trigger: 'blur', message: `请选择${text}` }];
  else return [{ required: true, message: `请${mode}${text}` }];
};
const ruleString = (text,required = true) => {
    return [{ required: required, whitespace: true, message: `请输入${text}` }];
};
const ruleNumber = (text, mode = "输入", whitespace = true) => {
  if (whitespace)
    return [{ required: true, whitespace: true, message: `请${mode}${text}`, type: 'number' }];
  else return [{ required: true, message: `请${mode}${text}` }];
};
// 校验一位小数
const ruleNumberDecimals = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: numberDecimals, message: `请输入正确格式的${text}` }
  ];
};

// 校验两位小数
const ruleNumberTwoDec = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: numberTwoDecimals, message: `请输入正确格式的${text}` }
  ];
};
// 校验四位小数
const ruleNumberFourDec = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: numberFourDecimals, message: `请输入正确格式的${text}` }
  ];
};
// 校验正整数
const ruleNumberInt = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: positiveInteger, message: `请输入正确格式的${text}` }
  ];
};
// 校验整数
const ruleNumberInteger = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: numberInteger, message: `请输入正确格式的${text}` }
  ];
};
// 校验浮动整数
const ruleFloatInteger = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: floatInteger, message: `请输入正确格式的${text}` }
  ];
};
// 校验有大小限制的数字
const ruleNumberLimit = (
  text,
  type = 2,
  min = 0,
  max = null,
  required = true,
  door = false
) => {
  if (door) {
    return [
      { required: false },
      () => ({
        validator() {
          return Promise.resolve();
        }
      })
    ];
  }
  return [
    { required: required, message: `请输入${text}` },
    () => ({
      validator(rule, value) {
        if (!value) return Promise.reject();
        switch (type) {
          case 0:
            if (!positiveInteger.test(value))
              return Promise.reject(`请输入正确的${text}`);
            break;
          case 1:
            if (!numberDecimals.test(value))
              return Promise.reject(`请输入正确的${text}`);
            break;
          case 2:
            if (!numberTwoDecimals.test(value))
              return Promise.reject(`请输入正确的${text}`);
            break;
          case 4:
            if (!numberFourDecimals.test(value))
              return Promise.reject(`请输入正确的${text}`);
            break;
          default:
        }
        let temp_value = Number(value);
        if (temp_value < min) return Promise.reject(`请输入正确的${text}`);
        else if (max && temp_value > max)
          return Promise.reject(`请输入正确的${text}`);
        return Promise.resolve();
      }
    })
  ];
};
// 选择日期或长期
const ruleDateRangeOrLongTerm = (form, type, depType, text) => {
  let longTerm = form.getFieldValue(depType);
  return [
    { required: !longTerm, message: `请选择${text}` },
    ({ getFieldValue, setFieldsValue }) => ({
      validator() {
        let rangeData = getFieldValue(type);
        if (longTerm) {
          setFieldsValue({
            [type]: null
          });
          return Promise.resolve();
        } else if (rangeData) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      }
    })
  ];
};
// 结束日期依赖开始日期
const ruleConfirmEndDate = (start, status, text) => {
  return [
    { required: status, message: `请选择${text}` },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        let startDate = getFieldValue(start) || 0
        let startTimeStamp = new Date(moment(startDate)).getTime()
        let endTimeStamp = new Date(moment(value)).getTime()
        if (!value || startTimeStamp < endTimeStamp) {
          return Promise.resolve();
        }
        return Promise.reject("结束时间不能小于等于开始时间");
      }
    })
  ];
};
//不得选择过去的时间
const rulePastTimes = (status, text) => {
  return [
    { required: status, message: `请选择${text}` },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        let sss = new Date();
        console.log(sss > new Date(value));
        if (sss > new Date(value)) {
          return Promise.reject("不得选择过去时间");
        }
        return Promise.resolve();
      }
    })
  ];
};
// 校验用户名
const ruleUser = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: userRegular, message: `请输入正确格式的${text}` }
  ];
};
//密码
const ruleUserPassword = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    {
      pattern: userPasswordRegular,
      message: `登录密码格式错误，只能由6-15位字母、数字和下划线组成`
    }
  ];
};
// 校验中文字符
const ruleName = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: userNameRegular, message: `请输入正确格式的${text}` }
  ];
};
// 校验邮箱
const ruleEmail = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: emailRegular, message: `请输入正确格式的${text}` }
  ];
};
// 验证手机号
const ruleTelephone = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: telephone, message: `请输入正确格式的${text}` }
  ];
};
// 验证证件号码
const ruleCertificateNo = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: certificateNo, message: `请输入正确格式的${text}` }
  ];
};

// 8-20位数字、字母或标点符号，不区分大小写
const ruleUserSpecific = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: userSpecific, message: `请输入正确格式的${text}` }
  ];
};
// 保留小数点后两位 用千位分割符
const ruleThousandthSeparator = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: numberThousandthSeparator, message: `请输入正确格式的${text}` }
  ];
};

// 校验两位小数 对比金额大小
const ruleContrastMoney = (
  text,
  receiveAmount,
  paymentApNoList = [],
  required = true
) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: numberTwoDecimals, message: `请输入正确格式的${text}` },
    ({ getFieldValue, setFieldsValue }) => ({
      validator(rule, value) {
        let useableAmount = "";
        if (paymentApNoList && paymentApNoList.length !== 0) {
          useableAmount = paymentApNoList.find(item => {
            return item.paymentApNo + "" === getFieldValue("paymentApNo") + "";
          }).useableAmount;
        }
        if (
          useableAmount === "" ||
          useableAmount === null ||
          useableAmount === undefined
        ) {
          return Promise.resolve();
        }
        if (getFieldValue("receiveAmount") - 0 > useableAmount - 0) {
          return Promise.reject("输入金额不能大于关联付款凭可登记额度");
        }
        return Promise.resolve();
      }
    })
  ];
};

// 相同的密码
const ruleSamePassword = (text, first, mode = "输入") => {
  return [
    { required: true, whitespace: true, message: `请${mode}${text}` },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (!value || getFieldValue([first]) === value) {
          return Promise.resolve();
        }
        return Promise.reject("请输入相同的密码");
      }
    })
  ];
};

// 校验是否正确的ip地址
const ruleIp = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: stringIp, message: `请输入正确格式的${text}` }
  ];
};

const ruleSwtich = (text, required = true) => {
  return [
    { required: required, message: `请输入${text}` },
    { pattern: numberSwitch, message: `${text}必须为0[有效]或1[失效]` }
  ];
};

const hasVal = (val) => {
  console.log(typeof val, 'typeof val')
  if (typeof val === 'string' || typeof val === 'object') {
    return val && val.length > 0
  } else {
    return val
  }
}

//策略配置 交易策略
const dealPolicy = (oneArr,TwoName,ThreeName,fourArr,checkfour,required = false, form) =>{
  if(checkfour) {return [
    ({getFieldValue,validateFields})=>({
       validator(rule,value){
         let flag = []
         let emptyArr = []
         oneArr.forEach(_ => flag.push(false))
         let flagOne = false
         let flagTwo = false
         let FlagThree = []
         fourArr.forEach(_ => FlagThree.push(false))
          if(oneArr){
            oneArr.forEach((item, i)=>{
              if (!hasVal(getFieldValue(item))) { emptyArr.push(item) }
              flag[i] = hasVal(getFieldValue(item))
            })
          }
          flagOne = getFieldValue(TwoName)
          flagTwo = getFieldValue(ThreeName)
          if (!flagOne) { emptyArr.push(TwoName) }
          if (!flagTwo) { emptyArr.push(ThreeName) }
          if(fourArr){
            fourArr.forEach((item, i)=>{
              if (!hasVal(getFieldValue(item))) { emptyArr.push(item) }
              FlagThree[i] = hasVal(getFieldValue(item))
            })
          }
           if(flag.find(item => item)||flagOne||flagTwo||FlagThree.find(item => item)){
             form.resetFields(emptyArr);
             return Promise.resolve();
           } else {
            return Promise.reject('至少输入一条限额')
           }
       }
     })]} else{
        return [
          { required: required, message: `请输入限额` },
          { pattern: positiveInteger, message: `请输入正确格式的限额` },
          ({getFieldValue,validateFields})=>({
            validator(rule,value){
              let flag = true
              let flagOne = false
              let flagTwo = false
              let FlagThree = true
                if(oneArr){
                  oneArr.map(item=>{
                    if(!getFieldValue(item)){
                      flag = false
                    }
                    return true
                  })
                }
                if(getFieldValue(TwoName)){
                  flagOne = true
                }
                if(getFieldValue(ThreeName)){
                  flagTwo = true
                }
                console.log(fourArr)
                if(fourArr){
                  fourArr.map(item=>{
                    if(!getFieldValue(item)){
                      FlagThree = false
                    }
                    return true
                  })
                }
                setTimeout(() => {
                  console.log(flag,flagOne,flagTwo,FlagThree)
                }, 500);
                if(flag||flagOne||flagTwo||FlagThree){
                  console.log('执行',ThreeName)
                  return Promise.resolve();
                }
                if(flag){
                  return Promise.resolve();
                }
                console.log('sss')
                return Promise.reject('至少输入一条限额')
            }
          })
        ]}
}


export default {
  ruleCommon,
  ruleString,
  ruleNumber,
  ruleNumberDecimals,
  ruleNumberTwoDec,
  ruleNumberFourDec,
  ruleNumberInt,
  ruleNumberInteger,
  ruleNumberLimit,
  ruleDateRangeOrLongTerm,
  ruleConfirmEndDate,
  ruleName,
  ruleUser,
  ruleEmail,
  ruleTelephone,
  ruleCertificateNo,
  ruleUserPassword,
  ruleUserSpecific,
  ruleThousandthSeparator,
  ruleFloatInteger,
  ruleContrastMoney,
  ruleSamePassword,
  ruleIp,
  ruleSwtich,
  rulePastTimes,
  dealPolicy,
  ruleSelect
};
