// redux 数据存储 localStorage 方法
import produce from 'immer';

import { dropByCacheKey, getCachingKeys } from 'react-router-cache-route'

import { Storage } from '@/utils';

// navTaps_localStorage
export const setStorageNavTaps = (state, { type, payload }) => {
  let navTaps = state;
  let newNavTaps = null;
  switch (type) {
    case 'ADD_NAV_TAPS':
      newNavTaps = produce(navTaps, (draft) => { draft.push(payload) })
      break;

    case 'DELETE_NAV_TAPS':
      newNavTaps = produce(navTaps, (draft) => {
        draft.splice(payload, 1)
        window.ee.emit(`close_${navTaps[payload].key}`, navTaps[payload].reload + 1);

        let hasPageCache = getCachingKeys().includes(`${navTaps[payload].key}`)
        if (hasPageCache) {
          dropByCacheKey(`${navTaps[payload].key}`)
        }
      })
      break;

    case 'UPDATE_NAV_TAPS_RELOAD':
      newNavTaps = produce(navTaps, (draft) => {
        window.ee.emit(`reload_${navTaps[payload].key}`, navTaps[payload].reload + 1);
        draft[payload].reload++
      })
      break;

    case 'UPDATE_NAV_TAPS_SHOW':
      newNavTaps = produce(navTaps, (draft) => {
        navTaps.forEach((item, i) => {
          draft[i].isShow = payload === i
        })
       })
      break;

    case 'CLEAR_NAV_TAPS_SHOW':
      newNavTaps = produce(navTaps, (draft) => {
        navTaps.forEach(item => { window.ee.emit(`reload_${item.key}`); })
        draft.splice(1, navTaps.length - 1)
       })
      break;

    case 'CLOSE_OTHER_NAV_TAPS_SHOW':
      console.log('5');
      console.log(navTaps);
      newNavTaps = produce(navTaps, (draft) => {
        let _cacheNavTap = navTaps.slice(0)[payload]
        draft.splice(1, navTaps.length - 1)
          navTaps.forEach(item => {
            if(_cacheNavTap.key !== item.key){ window.ee.emit(`reload_${item.key}`);}
          })
        if (payload !== 0) draft.push(_cacheNavTap)
       })
      break;

    default:
      newNavTaps = navTaps
      break;
  }
  Storage.setStorage('NAV_TAPS', newNavTaps);
}

export const setStorageNavCache = (key, cacheData) => {
  console.log('key cacheData', key, cacheData)
}

export default {
  setStorageNavTaps     // 导航
}
