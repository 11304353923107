import React from 'react';
import { Form, Input, Button } from 'antd'
import { Rules } from '@/utils'

const ChangePassword = ({ onCancel, onFinish, form }) => {
  return (
    <div>
      <Form form={form} className="form__box--modifyPassword label--width80" onFinish={onFinish} >
        <Form.Item label="登录密码" rules={Rules.ruleCommon('登录密码')} name="loginPassword">
          <Input.Password />
        </Form.Item>
        <Form.Item label="新密码" rules={Rules.ruleCommon('新密码')} name="newPassword">
          <Input.Password />
        </Form.Item>
        <Form.Item label="确认新密码" name="confirmPassword" rules={Rules.ruleSamePassword('新密码', 'newPassword')}>
          <Input.Password />
        </Form.Item>
        <div className="modal-btn__box">
          <Button htmlType="submit" type="primary" >确认</Button>
          <Button onClick={onCancel}>返回</Button>
        </div>
      </Form>
    </div>
  )
}

export default ChangePassword
