import React, { memo } from 'react'
// import { useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import Icon, { MenuUnfoldOutlined, MenuFoldOutlined, CloseCircleOutlined, EditOutlined } from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'
// utils
// import { Utils } from '@/utils';

import './HeadBar.less'

// const C_URL = 'system-updatePassword'

const HeadBar = ({ collapsed, onTriggerCollapsed, userName, userAvatar, onUserAvatarClick }) => {
  // 权限数据
  // const authorityState = useSelector(state => state.authority);

  return (
    <div className="header-bar__container flex flex-justify-between">
      <div className="icon__collapsed" onClick={onTriggerCollapsed.bind(this, collapsed)}>
        <Icon component={collapsed ? MenuUnfoldOutlined : MenuFoldOutlined} />
      </div>
      <div className="user__box flex">
        <div className="user__name">用户名:{userName}</div>
        <Dropdown className="user__avatar" trigger="click" overlay={ (
          <Menu onClick={onUserAvatarClick.bind(this)}>
            {/* <Menu.Item key="clear"><DeleteOutlined />清空缓存</Menu.Item> */}
            <Menu.Item key="changePassword"><EditOutlined />修改密码</Menu.Item>
            <Menu.Item key="close"><CloseCircleOutlined />安全退出</Menu.Item>
          </Menu>
        ) }>
          <div className="user__avatar flex flex-align-items-center">
            <div className="img__box flex flex-align-items-center">
              <img className="avatar_img" src={userAvatar} alt=""/>
            </div>
          </div>
        </Dropdown>
      </div>
    </div>
  )

}

export default memo(HeadBar)

HeadBar.propTypes = {
  /**
   * 菜单是否收起状态
   */
  collapsed: PropTypes.bool.isRequired,
  /**
   * 切换菜单收起按钮事件
   */
  onTriggerCollapsed: PropTypes.func.isRequired,
  /**
   * 用户名称
   */
  userName: PropTypes.string.isRequired,
  /**
   * 用户头像
   */
  userAvatar: PropTypes.string.isRequired
};

HeadBar.defaultProps = {
  collapsed: false,
  onTriggerCollapsed: undefined,
  userName: '',
  userAvatar: ''
};
