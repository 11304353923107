import Model from '../index'

class Login extends Model {
  constructor() {
    super()
    this.requestServerPrefix = '/system'
  }
  //获取登录验证图片
  getCheckImg(options = {}) {
    options.url = '/web/system/captchaImage'
    return this.get(options)
  }
  //登录接口
  userLogin(options = {}) {
    options.url = '/web/system/login'
    return this.post(options)
  }
}

const loginModel = new Login()
export default loginModel
