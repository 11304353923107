const loadingRequests = [
  /** 报表管理 */
  "/report/web/orderReportQuery/queryBigbrandAnalysis", // 品牌消费统计 - 查询
  "/report/web/orderReportExport/exportBigbrandAnalysis", // 品牌消费统计 - 导出
  "/report/web/orderReportQuery/querySmallBrandAnalysis", // 小品牌消费统计 - 查询
  "/report/web/orderReportExport/exportSmallBrandAnalysis", // 小品牌消费统计 - 导出

  "/report/web/orderReportQuery/saleAreaProvinceAnalysisResponse", //区域消费统计-消费月度变化趋势(区域)
  "/report/web/orderReportExport/exportSaleAreaProvinceAnalysis", //区域消费统计-消费月度变化趋势(区域)导出
  "/report/web/orderReportQuery/saleAreaTkAnalysisResponse", //区域消费统计-消费月度变化趋势(承保单位)
  "/report/web/orderReportExport/exportSaleAreaTkAnalysis", //消费月度变化趋势(承保单位)导出
  "/report/web/orderReportQuery/saleAreaProvinceOnoffAnalysisResponse", //区域消费统计-消费线上线下月度报表(区域)
  "/report/web/orderReportExport/exportSaleAreaProvinceOnoffAnalysis", //消费线上线下月度报表(区域)导出
  "/report/web/orderReportQuery/saleAreaTkOnoffAnalysisResponse", //区域消费统计-消费线上线下月度报表(承保单位)
  "/report/web/orderReportExport/exportSaleAreaTkOnoffAnalysis", //消费线上线下月度报表(承保单位)导出

  "/report/web/orderReportQuery/saleReportMonth", //消费报表统计
  "/report/web/orderReportExport/exportOrderSummary", //消费报表统计导出
  "/report/web/orderReportQuery/saleAllOrderSaleResponse", //月消费量
  "/report/web/orderReportExport/exportOrderSale", //月消费量导出
  "/report/web/orderReportQuery/saleAllCardAlterationResponse", //当月新增卡片数量
  "/report/web/orderReportExport/exportCardAlteration", //当月新增卡片数量导出
  "/report/web/orderReportQuery/saleAllOrdersNumResponse", //当月交易订单数
  "/report/web/orderReportExport/exportOrdersNum", //当月交易订单数导出
  "/report/web/orderReportQuery/saleAllCardsSaleResponse", //当月消费卡号数
  "/report/web/orderReportExport/exportCardsSale", //当月消费卡号数导出
  "/report/web/orderReportQuery/saleAllMedicalCompanysSaleResponse", //当月新增门店数
  "/report/web/orderReportExport/exportMedicalCompanysSale", //当月新增门店数导出
  "/report/web/orderReportQuery/saleAllInureCompanysResponse", //当月新增承保单位数
  "/report/web/orderReportExport/exportInureCompanys", //当月新增承保单位数导出
  "/report/web/orderReportQuery/saleAllInurCompanysResponse", //当月新增投保单位数
  "/report/web/orderReportExport/exportInurCompanys", //当月新增投保单位数导出
  "/report/web/orderReportQuery/saleAllGroupPlicyNosResponse", //当月新增团单数
  "/report/web/orderReportExport/exportGroupPlicyNos", //当月新增团单数导出

  "/report/web/orderReportQuery/saleReportOrderOnoffAnalysisResponse", //线上线下消费统计
  "/report/web/orderReportExport/exportSaleOnoffAnalysis", //线上线下消费统计导出

  "/report/web/orderReportQuery/queryCardsChangeProvince", //区域客户规模变化表-区域
  "/report/web/orderReportExport/exportCardsChangeProvince", //区域客户规模变化表（区域）导出
  "/report/web/orderReportQuery/queryCardsChangeInsurCompany", //区域客户规模变化表-承保单位
  "/report/web/orderReportExport/exportCardsChangeInsurCompany", //区域客户规模变化表（承保单位）导出

  "/report/web/staticChartQuery/exportCustomerConsumption", //总览导出
  "/report/web/staticChartQuery/exportConsumptionStatistics", //总览导出
  "/report/web/staticChartQuery/exportConsumptionPreference", //总览导出
  "/report/web/staticChartQuery/exportCustomerConsumptionStatistics", //总览导出
  "/report/web/staticChartQuery/exportCustomerConsumptionPreference", //总览导出
  "/report/web/staticChartQuery/exportCompanyConsumptionPreference", //总览导出
  "/report/web/staticChartQuery/exportBigBrandConsumptionPreference", //总览导出
  "/report/web/staticChartQuery/exportTopTwentyStoreConsumptionPreference", //总览导出

  /* "/report/web/homePage/queryAccountCompanyCount", //总览
  "/report/web/homePage/queryAccountCompanyTypeCount", //总览
  "/report/web/homePage/queryAreaCount", //总览
  "/report/web/homePage/queryCityCount", //总览
  "/report/web/homePage/queryCommodityCount", //总览
  "/report/web/homePage/queryMedTotal", //总览
  "/report/web/homePage/queryMedicalStoreCount", //总览
  "/report/web/homePage/queryOrderCount", //总览
  "/report/web/homePage/queryProvinceCount", //总览
  "/report/web/homePage/queryTotal", //总览 */

]

export default loadingRequests;
