import _ from 'lodash'
import {
  ExperimentOutlined,
  BookOutlined,
  ToolOutlined,
} from '@ant-design/icons';

const MENU = [
  // {
  //   id: _.uniqueId(),
  //   exact: true,
  //   menuUrl: 'home',
  //   menuName: 'Home',
  //   menuIcon: AppstoreOutlined,
  // },
  // {
  //   id: _.uniqueId(),
  //   menuUrl: 'sandwiches',
  //   menuName: 'Sandwiches',
  //   menuIcon: AppstoreOutlined,
  // },
  // // 多级导航
  // {
  //   id: _.uniqueId(),
  //   menuUrl: 'tacos',
  //   menuName: 'Tacos',
  //   menuIcon: ExceptionOutlined,
  //   children: [
  //     {
  //       id: _.uniqueId(),
  //       menuUrl: 'cart',
  //       menuName: 'Cart',
  //     },
  //     {
  //       id: _.uniqueId(),
  //       menuUrl: 'bus',
  //       menuName: 'Bus'
  //     },
  //   ],
  // },
  {
    id: _.uniqueId(),
    menuUrl: 'card-manage',
    menuName: '卡号管理',
    menuIcon: ExperimentOutlined,
    menuType: 'M',
    children: [
      {
        id: _.uniqueId(),
        menuUrl: 'card-cardQuery',
        menuName: '卡号查询',
        menuType: 'C'
      },
      {
        id: _.uniqueId(),
        menuUrl: 'card-cardCreate',
        menuName: '卡号生成',
        menuType: 'C'
      },
      {
        id: _.uniqueId(),
        menuUrl: 'card-letter',
        menuName: '密码函导出',
        menuType: 'C'
      },
      {
        id: _.uniqueId(),
        menuUrl: 'card-cardReplace',
        menuName: '补卡管理',
        menuType: 'C'
      },
      {
        id: _.uniqueId(),
        menuUrl: 'card-cardNewImport',
        menuName: '新开卡批量导入',
        menuType: 'C'
      },
      {
        id: _.uniqueId(),
        menuUrl: 'card-cardAddAmount',
        menuName: '卡片增额',
        menuType: 'C'
      }
    ]
  },
  {
    id: _.uniqueId(),
    menuUrl: 'Log-query',
    menuName: '日志查询',
    menuIcon: BookOutlined,
    menuType: 'M',
    children: [
      {
        id: _.uniqueId(),
        menuUrl: 'log-loginLog',
        menuName: '登录日志',
        menuType: 'C'
      },
      {
        id: _.uniqueId(),
        menuUrl: 'log-loginLog',
        menuName: '系统日志',
        menuType: 'C'
      },
      {
        id: _.uniqueId(),
        menuUrl: 'log-cardLog',
        menuName: '卡操作日志',
        menuType: 'C'
      },
      {
        id: _.uniqueId(),
        menuUrl: 'log-accountLog',
        menuName: '对账日志',
        menuType: 'C'
      },
      {
        id: _.uniqueId(),
        menuUrl: 'log-orderLog',
        menuName: '订单日志',
        menuType: 'C'
      },
      {
        id: _.uniqueId(),
        menuUrl: 'log-claimLog',
        menuName: '理赔日志',
        menuType: 'C'
      },
      {
        id: _.uniqueId(),
        menuUrl: 'log-balanceLog',
        menuName: '余额变更日志',
        menuType: 'C'
      },
      {
        id: _.uniqueId(),
        menuUrl: 'log-strategyLog',
        menuName: '策略日志',
        menuType: 'C'
      },
      {
        id: _.uniqueId(),
        menuUrl: 'log-openApiLog',
        menuName: '接口日志',
        menuType: 'C'
      }
    ]
  },
  {
    id: _.uniqueId(),
    menuUrl: 'system-setting',
    menuName: '系统管理',
    menuIcon: ToolOutlined,
    menuType: 'M',
    children: [
      {
        id: _.uniqueId(),
        menuUrl: 'system-notice',
        menuName: '公告管理',
        menuType: 'C'
      },
      {
        id: _.uniqueId(),
        menuUrl: 'system-advertisement',
        menuName: '广告管理',
        menuType: 'C'
      },
      {
        id: _.uniqueId(),
        menuUrl: 'system-sms',
        menuName: '短信管理',
        menuType: 'C'
      },
      {
        id: _.uniqueId(),
        menuUrl: 'system-sysRole',
        menuName: '角色管理',
        menuType: 'C'
      },
      {
        id: _.uniqueId(),
        menuUrl: 'system-dictHead',
        menuName: '字典管理',
        menuType: 'C'
      },
      {
        id: _.uniqueId(),
        menuUrl: 'system-sysUser', // OK
        menuName: '账号管理',
        menuType: 'C'
      }
    ]
  },
  {
    id: _.uniqueId(),
    menuUrl: 'official-web',
    menuName: '官网后台管理',
    menuIcon: BookOutlined,
    menuType: "M",
    children: [
      {
        id: _.uniqueId(),
        menuUrl: 'website-healthCareInfo',
        menuName: '健康资讯管理',
        menuType: 'C'
      },
      {
        id: _.uniqueId(),
        menuUrl: 'website-officialGoods',
        menuName: "商品资讯管理",
        menuType: 'C'
      }
    ]
  },

  {
    id: _.uniqueId(),
    menuUrl: 'customer-manage',
    menuName: '客户管理',
    menuIcon: BookOutlined,
    menuType: "M",
    children: [
      {
        id: _.uniqueId(),
        menuUrl: 'customer-customerInfo',
        menuName: '客户信息',
        menuType: 'C'
      },
      {
        id: _.uniqueId(),
        menuUrl: 'customer-customerRelative',
        menuName: "客户关系人管理",
        menuType: 'C'
      }
    ]
  },
  {
    id:_.uniqueId(),
    menuUrl:'merchants-manage',
    menuName:"商户管理",
    menuIcon: BookOutlined,
    menuType:"M",
    children:[
      {
        id:_.uniqueId(),
        menuUrl:"medical",
        menuName:"门店管理",
        menuType:"C"
      },
      {
        id:_.uniqueId(),
        menuUrl:"accountCompany",
        menuName:"结算主体管理",
        menuType:"C"
      },
      {
        id:_.uniqueId(),
        menuUrl:"storeEvaluate",
        menuName:"门店评价管理",
        menuType:"C"
      },
      {
        id:_.uniqueId(),
        menuUrl:"commodityClassification",
        menuName:"商品白名单审核",
        menuType:"C"
      },
      {
        id:_.uniqueId(),
        menuUrl:"commodityWhiteList",
        menuName:"商品白名单列表",
        menuType:"C"
      },
      {
        id:_.uniqueId(),
        menuUrl:"medicalUserUpdate",
        menuName:"月末账号修改结算主体",
        menuType:"C"
      },
    ]
  },
  {
    id:_.uniqueId(),
    menuUrl:'claims-center',
    menuName:"理赔管理",
    menuIcon: BookOutlined,
    menuType:"M",
    children:[
      {
        id:_.uniqueId(),
        menuUrl:"claim-claim",
        menuName:"理赔列表",
        menuType:"C"
      },
      {
        id:_.uniqueId(),
        menuUrl:"claim-batchClaim",
        menuName:"批量理赔",
        menuType:"C"
      }
    ]
  },
  {
    id:_.uniqueId(),
    menuUrl:'appointment-center',
    menuName:"预约中心",
    menuIcon: BookOutlined,
    menuType:"M",
    children:[
      {
        id:_.uniqueId(),
        menuUrl:"bookedInfo-setMeal",
        menuName:"预约套餐",
        menuType:"C"
      },
      {
        id:_.uniqueId(),
        menuUrl:"bookedInfo-bookedInfoQuery",
        menuName:"预约查询",
        menuType:"C"
      }
    ]
  },
  {
    id:_.uniqueId(),
    menuUrl:'order-manage',
    menuName:"订单管理",
    menuIcon: BookOutlined,
    menuType:"M",
    children:[
      {
        id:_.uniqueId(),
        menuUrl:"order-orderQuery",
        menuName:"订单查询",
        menuType:"C"
      }
    ]
  },
  {
    id:_.uniqueId(),
    menuUrl:'policy-manage',
    menuName:"策略管理",
    menuIcon:BookOutlined,
    menuType:"M",
    children:[
      {
        id:_.uniqueId(),
        menuUrl:'strategy-strategyConfig',
        menuName:"策略配置",
        menuType:"c"
      },
      {
        id:_.uniqueId(),
        menuUrl:'strategy-strategyTemplate',
        menuName:'策略模板',
        menuType:"c"
      },
      {
        id:_.uniqueId(),
        menuUrl:'strategy-strategyPolicy',
        menuName:'策略关联',
        menuType:"c"
      },
      {
        id:_.uniqueId(),
        menuUrl:'strategy-discount',
        menuName:'优惠折扣',
        menuType:"c"
      }
    ]
  },{
    id:_.uniqueId(),
    menuUrl:"reconciliation-manage",
    menuName:"对账管理",
    menuIcon:BookOutlined,
    menuType:"M",
    children:[
      {
        id:_.uniqueId(),
        menuUrl:"account-accountBase",
        menuName:"结算基础数据",
        menuType:"c"
      },
      {
        id:_.uniqueId(),
        menuUrl:"account-accountCompany",
        menuName:"商户结算列表",
        menuType:"c"
      },{
        id:_.uniqueId(),
        menuUrl:"account-insuranceCompany",
        menuName:"资金方结算列表",
        menuType:'c'
      },{
        id:_.uniqueId(),
        menuUrl:"account-qjPayment",
        menuName:"统一结算列表",
        menuType:"c"
      }
    ]
  },
  {
    id:_.uniqueId(),
    menuUrl:'statistical-query',
    menuName:"统计查询",
    menuIcon:BookOutlined,
    menuType:"M",
    children:[
      {
        id:_.uniqueId(),
        menuUrl:'statistics-openCardCount',
        menuName:"开卡统计",
        menuType:"c"
      },
      {
        id:_.uniqueId(),
        menuUrl:'statistics-cardSaleCount',
        menuName:'卡消费统计',
        menuType:"c"
      },
      {
        id:_.uniqueId(),
        menuUrl:'statistics-companySaleCount',
        menuName:'投保单位消费统计',
        menuType:"c"
      },
      {
        id:_.uniqueId(),
        menuUrl:'statistics-daySaleCount',
        menuName:'日消费统计',
        menuType:"c"
      }
    ]
  },
  {
    id: _.uniqueId(),
    menuUrl: 'group-order-manage',
    menuName: '团单管理',
    menuIcon: BookOutlined,
    menuType: "M",
    children: [
      {
        id: _.uniqueId(),
        menuUrl: 'groupPolicy-groupPolicy',
        menuName: '团单管理',
        menuType: 'C'
      },
      {
        id: _.uniqueId(),
        menuUrl: 'groupPolicy-groupPolicyStrategy',
        menuName: '团单网点查询',
        menuType: 'C'
      }
    ]
  },
]

export default MENU
