import Model from "../index";

class System extends Model {
  constructor() {
    super();
    this.requestServerPrefix = "/system";
  }
  // 获取当前用户菜单树结构
  getUserMenuList(options = {}) {
    options.url = "/web/menu/getUserMenuList";
    return this.get(options);
  }
  // 获取所有菜单树结构
  getMenuList(options = {}) {
    options.url = "/web/menu/getMenuList";
    return this.get(options);
  }
}

const systemModel = new System();
export default systemModel;
