import Model from "../index";
import { Utils } from "@/utils";

class Common extends Model {
  constructor() {
    super();
    this.requestServerPrefix = "";
  }

  //承保单位第一简称模糊搜索
  listInsuranceCompanyFirstInfo(options = {}) {
    options.data = Utils.transJsonToForm(options.data || {});
    options.url = "/card/web/insureCompanyInfo/listInsuranceCompanyFirstInfo";
    return this.post(options);
  }
  //模糊资金方名称
  selectCompanyNameLike(options = {}) {
    options.url = "/card/web/insuranceCompany/selectCompanyNameLike";
    return this.get(options);
  }
  //模糊结算主体名称
  getAccountCompanyByCompanyName(options = {}) {
    options.url =
      "/store/web/store/accountCompany/getAccountCompanyByCompanyName";
    return this.get(options);
  }

  // 角色管理：角色列表
  getListRole(options = {}) {
    options.url = "/system/web/role/getList";
    return this.post(options);
  }
  //官网后台管理：健康资讯管理 -- 健康资讯类型
  getHealthyInfoType(options = {}) {
    options.url = "/system/web/healthCareType/selectHealthCareTypeList";
    return this.post(options);
  }

  // 接口描述：地区列表
  getAreaList(options = {}) {
    options.data = Utils.transJsonToForm(options.data || {});
    options.url = "/system/web/area/getAreaList";
    return this.post(options);
  }
  // 接口描述：地区省列表
  getProvinceList(options = {}) {
    options.url = "/system/web/area/getProvinceList";
    return this.post(options);
  }
  //系统日志：操作模块
  getoperationModule(options = {}) {
    options.url = "/system/web/dictHead/selectDictDetail";
    return this.post(options);
  }
  // 根据条件分页获取上传文件列表
  getImportHead(options = {}) {
    options.url = "/card/web/importHead/getImportHead";
    return this.post(options);
  }
  // 承保单位模糊搜索
  listInsuranceCompanyInfo(options = {}) {
    options.data = Utils.transJsonToForm(options.data || {});
    options.url = "/card/web/insureCompanyInfo/listInsuranceCompanyInfo";
    return this.post(options);
  }
  // 获取excel模板下载url
  getExcelUrl(options = {}) {
    options.url = "/system/web/excelTemplateDownload/getExcelUrl";
    return this.get(options);
  }
  // 查看文件，返回url
  getFilelUrl(options = {}) {
    options.url = "/card/web/importHead/getFilelUrl";
    return this.get(options);
  }
  // 根据类型获取品牌
  getBrandByType(options = {}) {
    options.data = Utils.transJsonToForm(options.data || {});
    // options.url = "/order/web/bookedInfoQuery/getStoreListByCompanyType";
    options.url = "/order/web/bookedInfoQuery/getBigBrandListByCompanyType"
    return this.post(options);
  }
  // 投保单位模糊搜索
  listInsureCompanyInfo(options = {}) {
    options.data = Utils.transJsonToForm(options.data || {});
    options.url = "/card/web/insureCompanyInfo/listInsureCompanyInfo";
    return this.post(options);
  }
  // 商品信息模糊搜索
  operateCommodityByName(options = {}) {
    options.data = Utils.transJsonToForm(options.data);
    options.url = "/store/web/commodity-white-list/operateCommodityByName";
    return this.post(options);
  }
  // 大品牌模糊搜索
  operateBigBrand(options = {}) {
    options.data = Utils.transJsonToForm(options.data || {});
    options.url = "/store/web/store/accountCompany/operateBigBrand";
    return this.post(options);
  }
  // 小品牌模糊搜索
  operateSmallBrand(options = {}) {
    options.data = Utils.transJsonToForm(options.data);
    options.url = "/store/web/medicalStore/operateSmallBrand";
    return this.post(options);
  }
  // 小品牌模糊搜索订单用
  operateSmallBrandOrder(options = {}) {
    options.data = Utils.transJsonToForm(options.data);
    options.url = "/store/web/medicalStore/operateSmallBrand";
    return this.post(options);
  }
  // 商户名称模糊搜索(结算主体名称)
  accountCompanyName(options = {}) {
    options.data = Utils.transJsonToForm(options.data);
    options.url = "/store/web/store/accountCompany/getAccountCompanyByCompanyName";
    return this.get(options);
  }
    // 商户名称模糊搜索
    getMerchantName(options = {}) {
      options.data = Utils.transJsonToForm(options.data);
      options.url = "/store/web/store/accountCompany/getMerchantName";
      return this.post(options);
    }
  
}

const commonModel = new Common();
export default commonModel;
