import React, { lazy } from 'react'

import { BasicLayout, NoFound, Forbidden, Login } from '@/app/Layout/index'
import Home from '@/pages/Home'
import Sandwiches from '@/pages/Demo/Sandwiches'
import Tacos from '@/pages/Demo/Tacos'
import Bus from '@/pages/Demo/Bus'
import Cart from '@/pages/Demo/Cart'
// import Login from '@/pages/Login'

// 报表管理
const ReportManage = lazy(() => import('@/pages/ReportManage'))
const ConsumptionReport = lazy(() => import('@/pages/ReportManage/ConsumptionReport')) // 消费报表统计
const ConsumptionReportView = lazy(() => import('@@/src/pages/ReportManage/components/ConsumptionReportSubmenu/ConsumptionReport')) // 消费报表统计
const OTOReport = lazy(() => import('@/pages/ReportManage/OTOReport')) // 线上线下报表统计
const RegionalConsumption = lazy(() => import('@/pages/ReportManage/RegionalConsumption')) // 区域消费统计
const BrandConsumption = lazy(() => import('@/pages/ReportManage/BrandConsumption')) // 品牌消费统计
const RegionalCustomerScaleChanges = lazy(() => import('@/pages/ReportManage/RegionalCustomerScaleChanges')) // 区域客户规模变化统计
const RegionalCustomerScaleChangesView = lazy(() => import('@@/src/pages/ReportManage/components/RegionalCustomerScaleChangesSubmenu/RegionalCustomerScaleChangesView')) // 区域客户规模变化统计
const StatisticsReport = lazy(() => import('@/pages/ReportManage/StatisticsReport')) // 报表总览

const routes = [
  // {
  //   path: '/',
  //   exact: true,
  //   component: () => <>欢迎首页</>
  // },
  {
    path: '/',
    exact: true,
    component: Login
  },
  {
    path: '/404',
    component: NoFound
  },
  {
    path: '/403',
    component: Forbidden
  },
  {
    component: BasicLayout,
    routes: [
      {
        path: "/hello",

        component: () => <>欢迎首页</>
      },
      {
        path: "/home",
        component: Home
      },
      {
        path: "/sandwiches",
        component: Sandwiches
      },
      {
        path: "/tacos",
        component: Tacos,
        routes: [
          {
            path: "/tacos/bus",
            component: Bus
          },
          {
            path: "/tacos/cart",
            component: Cart
          }
        ]
      },
      {
        path: "/report-manage",
        component: ReportManage,
        routes: [
          {
            path: "/report-manage/report-statisticsReport",
            component: StatisticsReport // 报表总览
          },
          {
            path: "/report-manage/report-consumptionReport",
            component: ConsumptionReport // 消费报表统计
          },
          {
            path: "/report-manage/report-consumptionReportView",
            component: ConsumptionReportView  // 消费报表统计
          },
          {
            path: "/report-manage/report-OTOReport",
            component: OTOReport  // 线上线下报表统计
          },
          {
            path: "/report-manage/report-regionalConsumption",
            component: RegionalConsumption  // 区域消费统计
          },
          {
            path: "/report-manage/report-brandConsumption",
            component: BrandConsumption  // 品牌消费统计
          },
          {
            path: "/report-manage/report-regionalCustomerScaleChanges",
            component: RegionalCustomerScaleChanges  // 区域客户规模变化统计
          },
          {
            path: "/report-manage/report-regionalCustomerScaleChangesView",
            component: RegionalCustomerScaleChangesView  // 区域客户规模变化统计
          },
        ]
      },
      {
        path: '*',
        component: NoFound
      }
    ]
  },
  {
    path: '*',
    component: NoFound
  },
];

export default routes
