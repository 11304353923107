import React, { memo, useState, useEffect, useCallback } from 'react'
import { Link, withRouter } from "react-router-dom"
// antd
import { Menu as AntdMenu } from 'antd'
import { ExperimentOutlined } from '@ant-design/icons'
// redux
import { useSelector, useDispatch } from 'react-redux'
import { addNavTaps, updateAuthority } from '@/store/actions'
// utils
import { Utils } from '@/utils'

import systemModel from '@/model/Global/menuModel'

const Menu = ({ location }) => {
  const navTapsState = useSelector(state => state.navTaps);
  const [menuItems, setMenuItems] = useState([]) // 菜单元素列表
  const [navList, setNavList] = useState({}) // 菜单数据列表
  // const [navTaps]
  const dispatch = useDispatch()

  // 生成菜单树
  const getMenus = useCallback((menuArray, isSiderFold = true, parentPath = '/') => {
    return menuArray.map((item) => {
      /**
       * item.key item.menuUrl
       * item.children item.children
       * item.icon item.menuIcon
       * item.name item.menuName
       * item.type item.menuType 菜单类型（M目录 C菜单 F按钮）
       */
      // if (!['M', 'C'].includes(item.menuType)) { return false }
      const linkTo = parentPath + item.menuUrl
      // 生成 列表 (路由 -> 名称)
      navList[linkTo] = item.menuName
      // item.menuType === 'M' 假定 菜单只有两级 M和C，C之下是按钮不参与循环
      if (item.children && item.children.length && item.menuType === 'M'&& item.menuUrl === "report-manage") {
        setNavList(navList)
        return (
          <AntdMenu.SubMenu key={`${linkTo}_submenu`} icon={(item.menuIcon) ? <ExperimentOutlined /> : ''} title={item.menuName}>
            {getMenus(item.children, isSiderFold, `${linkTo}/`)}
          </AntdMenu.SubMenu>
        )
      }
      if (item.menuType === "C"){
        return (
          <AntdMenu.Item key={linkTo}>
            <Link to={linkTo}>
              {item.menuName}
            </Link>
          </AntdMenu.Item>
        )
      }
      return ""
    })
  }, [navList])

  useEffect(() => {
    systemModel.getUserMenuList().then(res => {
      if (res.datas) {
        setMenuItems(getMenus(res.datas))
        let authority = Utils.transformAuthority(res.datas)
        //console.log('authority', authority, res.datas);
        dispatch(updateAuthority(authority))
      }
    })
    // setMenuItems(getMenus(MENU))
  }, [getMenus, dispatch])

  // 菜单点击事件
  const handleClickNavMenu = (props) => {
    let key = props.key
    let title = navList[props.key]

    let findedNavTapsIndex = navTapsState.findIndex(item => item.key === key)
    if (findedNavTapsIndex === -1) {
      let newNavTap = { key, title, isShow: true, reload: 0 }
      if (!newNavTap) return
      // 添加 navTaps 菜单
      dispatch(addNavTaps(newNavTap))
    } else {
      // 菜单已存在，修改isShow属性，改为展示中
      // dispatch(updateNavTapsShow(findedNavTapsIndex))
    }
  }

  const getOpenKeys = () => {
    let pathArr = location.pathname.split('/');
    let level = pathArr.length - 1;
    //console.log(pathArr)
    let openKeys = []
    if (level > 1) {
      let parentPath = location.pathname.substr(0, location.pathname.lastIndexOf('/'))
      openKeys = [`${parentPath}_submenu`]
    } else {
    }
    return {
      defaultOpenKeys: openKeys
    }
  }


  // const handleOpenNavMenu = (openKey) => {
  //   console.log(openKey)
  // }

  return (
    <AntdMenu
      key="1"
      mode="inline"
      selectedKeys={[location.pathname]}
      onClick={handleClickNavMenu}
      // onOpenChange={handleOpenNavMenu}
      {...getOpenKeys()}
      >
        {menuItems}
    </AntdMenu>

  )
}

export default withRouter(memo(Menu))
