import 'braft-editor/dist/index.css';
import React, { useEffect, useState, useCallback }  from 'react';

// import { Form, Input, Button } from 'antd';

import { useImmer } from 'use-immer';

import BraftEditor from 'braft-editor';

import { Utils } from '@/utils'

const Cart = () => {
  const [isLivinig, setIsLivinig] = useState(false);
  const [braftEdit, setBraftEdit] = useImmer({
    editorState: BraftEditor.createEditorState(null),
    onChange: Utils.debounce((editorState) => {
      const htmlString = editorState.toHTML()
      console.log(htmlString)
      setBraftEdit(draft => {
        draft.editorState = htmlString
      })
    }, 300)
  });

  const setEditorContentAsync = useCallback(() => {
    if (!isLivinig) return false;
  }, [isLivinig])

  useEffect(() => {
    setIsLivinig(true)
    // 3秒后更改编辑器内容
    setTimeout(setEditorContentAsync, 3000)
    return () => {
      setIsLivinig(false)
    }
  }, [setEditorContentAsync])

  return <>
    <h5>输出内容</h5>
    <div className="editor-wrapper">
      <BraftEditor
        value={braftEdit.editorState}
        onChange={braftEdit.onChange}
      />
    </div>
  </>;
}

export default Cart
