
/**
 * 接收接口数据
 * @param {array} datas
 * @param {string} type
 */
export const setEcharts = (datas, type) => {
  const optionNum = [];
  const optionDate = [];

  switch(type) {
    case 'consumption':
      datas.forEach((item, i) => {
        optionNum[i] = item.orderSale;
        optionDate[i] = item.yearMonth;
      })
      break;
    case 'card':
      datas.forEach((item, i) => {
        optionNum[i] = item.cardAlterationNew;
        optionDate[i] = item.yearMonth;
      })
      break;
    case 'order':
      datas.forEach((item, i) => {
        optionNum[i] = item.ordersNum;
        optionDate[i] = item.yearMonth;
      })
      break;
    case 'cardNum':
      datas.forEach((item, i) => {
        optionNum[i] = item.cardsSale;
        optionDate[i] = item.yearMonth;
      })
      break;
    case 'store':
      datas.forEach((item, i) => {
        optionNum[i] = item.medicalCompanysSale;
        optionDate[i] = item.yearMonth;
      })
      break;
    case 'insuranceCompany':
      datas.forEach((item, i) => {
        optionNum[i] = item.inureCompanyNew;
        optionDate[i] = item.yearMonth;
      })
      break;
    case 'company':
      datas.forEach((item, i) => {
        optionNum[i] = item.inurCompanyNew;
        optionDate[i] = item.yearMonth;
      })
      break;
    case 'groupPolicy':
      datas.forEach((item, i) => {
        optionNum[i] = item.groupPlicyNoNew;
        optionDate[i] = item.yearMonth;
      })
      break;
    default:
      break;
  }

  

  // 指定图表的配置项和数据
  let option = {
    title: {
      text: '消费量变化报表'
    },
    xAxis : {
      data : optionDate,
      axisLabel :{
          rotate:60
      },
  },
    yAxis: {},
    series: [{
      name: '消费量变化报表',
      type: 'line',
      data: optionNum,
      color:["#3c8dbc"]
    }],
    toolbox : {
      show : true,
      feature : {
        magicType : {
          show : true,
          type : [ 'line', 'bar' ],
          title: {'line':"切换为折线图",'bar':"切换为柱状图"}
        },
        restore : {
          show : true,
          title: '还原',
        },
        saveAsImage : {
          show : true,
          title : '保存为图片',
          type : 'png',
          lang : [ '点击保存' ]
        }
      }
    },
  };
  return option
}

export default {
  setEcharts, // 设置图表
}
