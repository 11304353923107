import Model from '../index'

class Password extends Model {
  constructor() {
    super()
    this.requestServerPrefix = '/system'
  }
  // 用户登录是否过期
  userLoginExpire(options = {}) {
    options.url = "/web/system/userLoginExpire";
    return this.get(options);
  }
  //修改密码
  modify(options = {}) {
    options.url = "/web/user/updatePassword"
    return this.post(options)
  }
}

const modifyPassword = new Password()
export default modifyPassword
