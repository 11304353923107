import React from 'react'
import {
  Link
} from "react-router-dom"
import renderRoutes from "@/routes/renderRoutes"


const Tacos = ({ route }) => {
  return (
    <div>
      <h2>Tacos</h2>
      <ul>
        <li>
          <Link to="/tacos/bus">Bus</Link>
        </li>
        <li>
          <Link to="/tacos/cart">Cart</Link>
        </li>
      </ul>
      {renderRoutes(route.routes)}
    </div>
  );
}

export default Tacos
