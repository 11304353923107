import React, { memo, useState, useEffect, useRef } from 'react'
import renderRoutes from "@/routes/renderRoutes"
// import BasicLayoutModel from "@/model/BasicLayout/BasicLayout"; //引入接口
// antd
import { ConfigProvider, Layout, Modal, Form } from 'antd'
import { useImmer } from "use-immer"
// components
import {
  SideBar,
  TabBar,
  HeadBar,
  ChangePassword
} from '@/app/Layout'

import userAvatar from '@/assets/iconlogo.png'

import { withRouter, useHistory } from "react-router-dom"

// redux
import store from '@/store/index'
import { Provider, useSelector, useDispatch } from 'react-redux'
import { updateCollapsed, clearNavTaps } from '@/store/actions'

// utils
import { NAV_TAPS_INITIAL, NAV_CACHE_INITIAL, OPTIONS_MODAL_FUN, Storage, Utils, LoadingRef } from '@/utils'
import modifyModel from '@/model/SystemSetting/passwordModel'
import LoadingAlert from '@/components/LoadingAlert';

import './BasicLayout.less'

const { Header, Sider } = Layout

const BasicLayout = ({ route }) => {
  const loadingRef = useRef();
  const history = useHistory()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [userName, setUserName] = useState('')

  const sideBarProps = {
    collapsed: useSelector(state => state.sideBarCollapsed),
    onTriggerCollapsed: (_collapsed) => {
      dispatch(updateCollapsed(_collapsed))
    },
    onUserAvatarClick: (e) => {
      switch (e.key) {
        case 'clear':
          Storage.setStorage('NAV_TAPS', NAV_TAPS_INITIAL)
          dispatch(clearNavTaps())
          history.push('/home')
          break;

        case 'changePassword':
          setModalInfo(draft => {
            draft.visible = true
          })
          break;

        case 'close':
          // history.push('/')
          Storage.setStorage('userId', '')
          Storage.setStorage("Authorization", ``)
          Storage.setStorage('userName', '')
          Storage.setStorage('userType', '')
          dispatch(clearNavTaps());
          Storage.setStorage('NAV_TAPS', NAV_TAPS_INITIAL);
          Storage.setStorage('NAV_CACHE', NAV_CACHE_INITIAL);
          window.location.href = '/'
          break;

        default:
          break;
      }
    }
  }

  const [modalInfo, setModalInfo] = useImmer({
    visible: false
  });

  const modalInfoFn = {
    onFinish: Utils.debounce((selectedRows) => {
      setModalInfo(draft => {
        let data = form.getFieldValue()
        modifyModel.modify({
          data: {
            newPassword: data.newPassword,
            oldPassword: data.loginPassword,
            userId: localStorage.getItem('userId')
          }
        }).then((datas) => {
          if (datas.message === 'success') {
            setModalInfo(draft => {
              draft.visible = false
            })
            setTimeout(() => {
              Utils.successMsg('修改成功 请重新登录')
              window.localStorage.clear()
              form.resetFields()
              history.replace('/')
            }, 500);

          }
        })
      })
    }),

    onCancel: Utils.debounce(() => {
      setModalInfo(draft => {
        draft.visible = false
      })
      form.resetFields()
    })
  }

  useEffect(() => {
    modifyModel.userLoginExpire().then(({message}) => {
      if(!message){
        Utils.warnMsg({
          title: "cookie过期",
          content: "请重新登录",
          onOk:()=>{
            window.localStorage.clear()
            history.replace('/')
          }
        })
      }
    }).catch(err => {
      Utils.warnMsg({
        title: "cookie过期",
        content: "请重新登录",
        onOk:()=>{
          window.localStorage.clear()
          history.replace('/')
        }
      })
    })
  }, [form, history])

  useEffect(() => {
    let userName = Storage.getStorage('userName')
    if (typeof userName === 'boolean') userName = ''
    setUserName(userName)
  }, [])

  useEffect(() => {
    LoadingRef.setRef(loadingRef)
  }, [loadingRef])

  // useEffect(() => {
  //   history.listen((location, action) => {
  //     console.log(
  //       `The current URL is ${location.pathname}${location.search}${location.hash}`
  //     );
  //     console.log(`The last navigation action was ${action}`);
  //   });
  // }, [history])

  const headBarProps = {
    ...sideBarProps,
    userName,
    userAvatar
  }

  return (
    <ConfigProvider componentSize="middle">
      <Provider store={store}>
        <Layout style={{ minHeight: '100vh' }} className="basic-layout__container">
          <Sider collapsed={sideBarProps.collapsed}>
            <SideBar />
          </Sider>
          <Layout className="site-layout">
            <Header className="site-layout-background" style={{ padding: 0 }}>
              <HeadBar {...headBarProps} />
            </Header>
            <div id="content">
              <TabBar />
              <div className="site-layout__box" id="id__layout-content">
                {renderRoutes(route.routes)}
              </div>
            </div>
          </Layout>
        </Layout>

        {/* 修改密码 */}
        <Modal
          {...OPTIONS_MODAL_FUN({
            collapsed: sideBarProps.collapsed
          })}
          title="修改账号密码"
          visible={modalInfo.visible}
          onCancel={modalInfoFn.onCancel}
        >
          <ChangePassword form={form} {...modalInfo} onFinish={modalInfoFn.onFinish} />
        </Modal>

        <LoadingAlert ref={loadingRef} />
      </Provider>
    </ConfigProvider>
  )
}

export default memo(withRouter(BasicLayout))
