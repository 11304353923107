import React, { useEffect } from 'react'
import { Input } from 'antd'
// utils
import { OPTIONS_INPUT_LENGTH } from '@/utils'
import { KeepAlive } from 'react-keep-alive'

// import DemoModel from '@/model/demoModel'

const Bus = () => {
  useEffect(() => {
    // DemoModel.getAll()
    //   .then(res => {
    //     console.log(res)
    //   })
  }, [])

  return (
    <KeepAlive id="Bus" name="Bus">
      <h3>Bus</h3>
      <Input {...OPTIONS_INPUT_LENGTH} />
    </KeepAlive>
  )
}

export default Bus
