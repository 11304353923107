import React, { memo } from 'react'

import { useSelector } from 'react-redux'

import './Logo.less'

const Logo = () => {
  const collapsed = useSelector(state => state.sideBarCollapsed)
  return (
    <div className="logo__container">
      { !collapsed ?
        <div className="logo--lg">
          Q-Health
          <span>企健网</span>
        </div> :
        <div className="logo--mini">Q</div>
      }
    </div>
  )
}
export default memo(Logo)
