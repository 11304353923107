import axios from 'axios'
import { Utils, Storage, LoadingRef } from '@/utils'

const TIME_OUT = 10 * 60 * 1000
// axios 配置
const httpAxios = axios.create({
  timeout: TIME_OUT // 请求超时时间
});//创建实例
httpAxios.defaults.timeout = TIME_OUT; //响应时间
// 当实例创建时设置默认配置
if (process.env.NODE_ENV === 'production') {
  httpAxios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
}
// httpAxios.defaults.headers.post['Content-Type'] = 'application/json'; //配置请求头
// httpAxios.defaults.headers.get['Content-Type'] = 'application/json'; //配置请求头
// httpAxios.defaults.headers.delete['Content-Type'] = 'application/json'; //配置请求头
// httpAxios.defaults.headers.put['Content-Type'] = 'application/json'; //配置请求头
// httpAxios.defaults.headers.common['Authorization'] = sessionStorage.getItem('Authorization')

// http request 拦截器
httpAxios.interceptors.request.use(
  (config) => {
    // 下拉框查询不触发loading弹窗
    if ((config.data && config.data.noLoadingRef === 'yes')) {
      delete config.data.noLoadingRef
    } else if ((config.params && config.params.noLoadingRef === 'yes')) {
      delete config.params.noLoadingRef
    } else {
      LoadingRef.handleOpenByUrl(config.url)
    }
    config.headers.Authorization = Storage.getStorage('Authorization') || ''
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// http response 拦截器:返回状态判断（添加响应拦截器）
httpAxios.interceptors.response.use(
  (response) => {
    if (response.config && response.config.url) {
      LoadingRef.handleCloseByUrl(response.config.url)
    }
    const res = response.data
    const config = response.config || {}
    if (res.success) return res
    if (response.request.responseType === 'blob' && response.status === 200) {
      const blob = new Blob([res])
      if (blob && blob.text) {
        return blob.text().then(result => {
          try {
            const jsonData = JSON.parse(result)
            if (!jsonData.success && jsonData.message) {
              Utils.errorMsg({
                title: `${jsonData.message || '未知错误'}`
              })
            } else {
              Utils.errorMsg({
                title: '未知错误'
              })
            }
            return null
          } catch (error) {
            return response
          }
        })
      } else {
        return response
      }
    }
    if (!config.transferError) { // 是否开启转接报错服务,不开启报错提示
      console.log(res)
      Utils.errorMsg({title: res.message ? Utils.str2dom(res.message.split('\n')) : '未知错误'})
    }
    return Promise.reject(res.message || '未知错误')
  },
  (error) => {
    if (error.config && error.config.url) {
      LoadingRef.handleCloseByUrl(error.config.url)
    }
    // 修改当前逻辑 需与 Utils.getErrStr 同步
    let response = error.response
    if (response) {
      let err = response.data
      if (err && response.status === 401) {
        if (err.code === '40002') {
          Utils.errorMsg({
            title: `${err.message || '未知错误'}`
          })
        } else {
          Utils.errorMsg({
            title: `${err.message || '未知错误'}, 请重新登录！`,
            content: '确认后跳转登录页面',
            onOk: () => {
              window.location.href = '/'
            }
          })
        }
        return Promise.reject(error)
      }
      if (response.status === 504) {
        Utils.errorMsg({title: '请求超时'})
        return Promise.reject(error)
      }
    } else {
      if (error && error.toString().indexOf('Error') >= 0) {
        Utils.errorMsg({title: error.toString()})
        return Promise.reject(error)
      } else {
        Utils.errorMsg({title: '未知错误'})
        return Promise.reject(error)
      }
    }
    return Promise.reject(error)
  }
)

export default {
  httpAxios,
  TIME_OUT
}
